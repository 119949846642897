import history from '../../settings/history';
import { createHTTPRequest, handleResponse, UNAUTHORIZED, LOGIN_URL } from './';
/**
 * Query a data provider and return a promise for a response
 *
 * @example
 * dataProvider(GET_ONE, 'posts', { id: 123 })
 *  => Promise.resolve({ data: { id: 123, title: "hello, world" } })
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the action type
 * @returns {Promise} the Promise for a response
 */

export const turnAPI = httpClient => (type, resource, payload) => {
  // REQUEST
  const { url, options } = createHTTPRequest(type, resource, payload);
  // RESPONSE
  return httpClient(url, options)
    .then(res => handleResponse(res, type))
    .catch(err => {
      if (err.status !== UNAUTHORIZED) throw err;
      if (err.status === UNAUTHORIZED) history.replace(LOGIN_URL);
      return err;
    });
};
