import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const styles = {
  parent: {
    width: '100%!important',
    display: 'flex',
    flexWrap: 'wrap'
  },
  child: {
    marginRight: '20px',
    flexGrow: 1,
    flexBasis: '256px',
    maxWidth: '256px'
  }
};

export class Row extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired
  };
  render() {
    const children = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { style: styles.child })
    );
    return <div style={styles.parent}>{children}</div>;
  }
}
