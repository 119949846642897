import React, { useState } from 'react';
import {
  Tab,
  Datagrid,
  ReferenceManyField,
  TextField,
  BooleanField,
  UPDATE
} from 'react-admin';
import Switch from '@material-ui/core/Switch';
import { CANDIDATE_RESOURCE } from 'providers/turn';
import SpinnerRectLoader from '../../../../components/SpinnerRectLoader';
import { Dialog } from '../../../../components';
import dataProvider from 'services/api';
import _ from 'lodash';

export const WatchlistRecordActivation = props => {
  if (props.record.active === false) {
    return <span>false</span>;
  }
  return <TextField {...props} source="active" />;
};

export const WatchlistRecordActivationSwitch = props => {
  return (
    <Switch
      checked={props.record.active}
      onChange={e => props.onChange(e, props.record.id)}
      name="switch"
    />
  );
};

export const WatchlistRecordsTab = ({ ...props }) => {
  const [state, setState] = useState({ loading: false });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');

  const handleSwitchChange = async (event, id) => {
    setState({ ...state, ['loading']: true });

    const resource = 'fcra_workers';
    const target = 'update_worker_watchlist_record';

    dataProvider(UPDATE, resource, {
      id,
      target
    })
      .then(response =>
        handleResponse(response, response.data.title, response.data.message)
      )
      .catch(error => handleError(error));
  };

  const handleResponse = (response, title, message) => {
    setState({ ...state, ['loading']: false });
    if (!_.isNil(response)) {
      setDialogOpen(true);
      setDialogTitle(title);
      setDialogMessage(message);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      window.location.reload();
    }
  };

  const handleError = error => {
    setState({ ...state, ['loading']: false });
    setDialogOpen(true);
    if (error.body) {
      this.props.setDialogMessage(
        <span>
          {error.body.message && <p>{`Message: ${error.body.message}`}</p>}
          {error.body.request_id && (
            <p>{`Request id: ${error.body.request_id}`}</p>
          )}
        </span>
      );
    } else {
      this.props.setDialogMessage('If this persist please contact support');
    }
  };

  return (
    <>
      <Dialog
        message={dialogMessage}
        title={dialogTitle}
        open={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
      {state.loading && <SpinnerRectLoader />}
      <Tab label="Watchlist" {...props}>
        <ReferenceManyField
          reference={CANDIDATE_RESOURCE}
          target="worker_watchlist_records"
          label=""
          {...props}
        >
          <Datagrid>
            <WatchlistRecordActivationSwitch onChange={handleSwitchChange} />
            <WatchlistRecordActivation source="active" />
            <TextField source="criminal_version" label="criminal_version" />
            <TextField source="id" label="ID" />
            <BooleanField source="records_match" label="records_match" />
            <TextField source="report_token" />
            <TextField source="address_id" />
            <TextField source="address_remarks" />
            <TextField source="alias_name" />
            <TextField source="alias_type" />
            <TextField source="call_sign" />
            <TextField source="citizenship" />
            <TextField source="constituancy" />
            <TextField source="country" />
            <TextField source="date_of_birth" />
            <TextField source="documents" />
            <TextField source="email_address" />
            <TextField source="eyes" />
            <TextField source="fax" />
            <TextField source="full_name" />
            <TextField source="grt" />
            <TextField source="hair" />
            <TextField source="hash" />
            <TextField source="height" />
            <TextField source="image_url" />
            <TextField source="languages" />
            <TextField source="legal_basis" />
            <TextField source="listing_date" />
            <TextField source="marks" />
            <TextField source="name_id" />
            <TextField source="offense" />
            <TextField source="official_title" />
            <TextField source="original_id" />
            <TextField source="page_url" />
            <TextField source="phone" />
            <TextField source="place_of_birth" />
            <TextField source="political_party" />
            <TextField source="program" />
            <TextField source="remarks" />
            <TextField source="score" />
            <TextField source="sex" />
            <TextField source="source" />
            <TextField source="tin" />
            <TextField source="title" />
            <TextField source="tonnage" />
            <TextField source="type" />
            <TextField source="user_id" />
            <TextField source="vessel_flag" />
            <TextField source="vessel_owner" />
            <TextField source="vessel_type" />
            <TextField source="wanted_by" />
            <TextField source="website" />
            <TextField source="weight" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </>
  );
};
