import auth0 from 'auth0-js';
import history from '../settings/history';
import runtimeEnv from '@mars/heroku-js-runtime-env';

const isTestEnv = runtimeEnv().REACT_APP_TEST_ENV === 'true';

class Auth {
  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.setSession = this.setSession.bind(this);
  }

  auth0 = new auth0.WebAuth({
    domain: 'turn.auth0.com',
    clientID: runtimeEnv().REACT_APP_AUTH_ID,
    redirectUri: `${window.location.origin}`,
    audience: runtimeEnv().REACT_APP_AUTH0_AUDIENCE,
    responseType: 'token id_token',
    scope: 'openid profile email'
  });

  login() {
    this.auth0.authorize();
  }

  async handleAuthentication() {
    await this.auth0.parseHash(async (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        const profile = this.generateProfile(authResult);
        const initial = localStorage.getItem('initial_url');
        await this.setSession(authResult);
        await this.setProfile(profile);
        if (initial) history.replace(initial);
        window.location.reload();
      }
    });
  }

  generateProfile(authResult) {
    const {
      family_name,
      given_name,
      locale,
      name,
      nickname,
      nonce,
      picture,
      sub,
      updated_at,
      email,
      email_verified
    } = authResult.idTokenPayload;
    return {
      family_name,
      given_name,
      locale,
      name,
      nickname,
      nonce,
      picture,
      sub,
      updated_at,
      email,
      email_verified
    };
  }

  async setProfile(profile) {
    await localStorage.setItem('profile', JSON.stringify(profile));
  }

  async setSession(authResult) {
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    await localStorage.setItem('access_token', authResult.accessToken);
    await localStorage.setItem('id_token', authResult.idToken);
    await localStorage.setItem('expires_at', expiresAt);
    history.replace('/');
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('profile');
    history.replace('/login');
    window.location.reload();
  }

  getProfile = () => JSON.parse(localStorage.getItem('profile'));

  isAuthenticated() {
    if (isTestEnv) return true;
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }
}
const auth = new Auth();
export default auth;
