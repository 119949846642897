import React, { useState } from 'react';
import {
  Tab,
  Datagrid,
  ReferenceManyField,
  TextField,
  UPDATE
} from 'react-admin';
import Switch from '@material-ui/core/Switch';
import { CANDIDATE_RESOURCE } from 'providers/turn';
import SpinnerRectLoader from '../../../../components/SpinnerRectLoader';
import { Dialog } from '../../../../components';
import dataProvider from 'services/api';
import _ from 'lodash';

export const MVRActionActivation = props => {
  if (props.record.active === false) {
    return <span>false</span>;
  }
  return <TextField {...props} source="active" sortable={false} />;
};

export const MVRActionActivationSwitch = props => {
  return (
    <Switch
      checked={props.record.active}
      onChange={e => props.onChange(e, props.record.id)}
      name="switch"
      sortable={false}
    />
  );
};

export const MvrActionTab = ({ ...props }) => {
  const [state, setState] = useState({ loading: false });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');

  const handleSwitchChange = async (event, id) => {
    setState({ ...state, ['loading']: true });

    const resource = 'fcra_workers';
    const target = 'update_worker_mvr_action';

    dataProvider(UPDATE, resource, {
      id,
      target
    })
      .then(response =>
        handleResponse(response, response.data.title, response.data.message)
      )
      .catch(error => handleError(error));
  };

  const handleResponse = (response, title, message) => {
    setState({ ...state, ['loading']: false });
    if (!_.isNil(response)) {
      setDialogOpen(true);
      setDialogTitle(title);
      setDialogMessage(message);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      window.location.reload();
    }
  };

  const handleError = error => {
    setState({ ...state, ['loading']: false });
    setDialogOpen(true);
    if (error.body) {
      this.props.setDialogMessage(
        <span>
          {error.body.message && <p>{`Message: ${error.body.message}`}</p>}
          {error.body.request_id && (
            <p>{`Request id: ${error.body.request_id}`}</p>
          )}
        </span>
      );
    } else {
      this.props.setDialogMessage('If this persist please contact support');
    }
  };

  return (
    <>
      <Dialog
        message={dialogMessage}
        title={dialogTitle}
        open={dialogOpen}
        setDialogOpen={setDialogOpen}
        sortable={false}
      />
      {state.loading && <SpinnerRectLoader sortable={false} />}
      <Tab label="Actions" {...props}>
        <ReferenceManyField
          reference={CANDIDATE_RESOURCE}
          target="worker_mvr_actions"
          label=""
          {...props}
        >
          <Datagrid>
            <MVRActionActivationSwitch onChange={handleSwitchChange} />
            <MVRActionActivation source="active" sortable={false} />
            <TextField source="id" label="ID" sortable={false} />
            <TextField source="action_code" sortable={false} />
            <TextField source="action_type" sortable={false} />
            <TextField
              source="locations[0]['docket']"
              label="Docket"
              sortable={false}
            />
            <TextField
              source="locations[0]['state']"
              label="State"
              sortable={false}
            />
            <TextField source="thru_date" sortable={false} />
            <TextField source="actual_end_date" sortable={false} />
            <TextField
              source="sub_violations[0]['acd_code']"
              label="ACD Code"
              sortable={false}
            />
            <TextField
              source="sub_violations[0]['acd_code_description']"
              label="ACD Description"
              sortable={false}
            />
            <TextField
              source="sub_violations[0]['key']"
              label="Key"
              sortable={false}
            />
            <TextField
              source="sub_violations[0]['descriptions'][0]['short_description']"
              label="Short Description"
              sortable={false}
            />
            <TextField
              source="sub_violations[0]['descriptions'][0]['state_description']"
              label="State Description"
              sortable={false}
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </>
  );
};
