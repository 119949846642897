export const EXACT_MATCH = ['ExactMatch', 'Match', 'SHOW', true];
export const NA_MATCH = ['NA', null];
export const NO_MATCH = ['HIDE', 'NoMatch', false];
export const PARTIAL_MATCH = [
  'PartialMatch',
  'MonthYearMatch',
  'DayMonthMatch',
  'YearMatch'
];

export const OTHER_ID = 'other_id';

export const TOOLTIP_LABELS = {
  criminalHit: {
    reported: `The hit is surfaced in the applicant report, partner report, and PDF version of partner report and can be considered a disqualifying reason.`,
    notReported: `The hit is not surfaced in the applicant report, partner report, and PDF version of partner report and cannot be considered a disqualifying reason.`
  },
  override_logic: {
    hide: `This record is enforced not to be reported.`,
    na: `This record doesn’t have an enforcement.`,
    show: `This record is enforced to be reported.`
  }
};

export const BG_COLOR_KEY_MAPPING = {
  dark_amber: {
    styles: {
      backgroundColor: 'rgb(253 230 138)',
      color: 'rgb(245 158 11)'
    }
  },
  dark_green: {
    styles: {
      backgroundColor: 'rgb(134 239 172)',
      color: 'rgb(22 163 74)'
    }
  },
  dark_grey: {
    styles: {
      backgroundColor: 'rgb(209 213 219)',
      color: 'rgb(75 85 99)'
    }
  },
  light_grey: {
    styles: {
      backgroundColor: '#EEEEEE',
      color: 'rgb(0 0 0)'
    }
  },
  red: {
    styles: {
      backgroundColor: 'rgb(252 165 165)',
      color: 'rgb(220 38 38)'
    }
  }
};
