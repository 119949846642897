import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'react-admin';
import { getCounties } from '../';

const perPage = 1000;

export class CountyInput extends Component {
  static propTypes = {
    state: PropTypes.string
  };
  constructor(props) {
    super(props);
    this.state = { counties: [] };
    this.currentState = undefined;
    this.awaitingServer = false;
  }
  async setCounties() {
    const { state } = this.props;
    const { currentState } = this;
    let { counties } = this.state;
    if (!state || currentState === state || this.awaitingServer) return;
    this.awaitingServer = true;
    counties = await getCounties(state, perPage);
    this.awaitingServer = false;
    this.currentState = state;
    this.setState({ counties });
  }
  render() {
    const { state, ...rest } = this.props;
    const { counties } = this.state;
    const { awaitingServer } = this;
    this.setCounties();
    return (
      <SelectInput
        disabled={counties.length === 0 || awaitingServer}
        choices={counties}
        optionValue="name"
        source="county"
        {...rest}
      />
    );
  }
}
