import _ from 'lodash';
import { CREATE, UPDATE } from 'react-admin';
import dataProvider from 'services/api';
import { toValidUrl } from '../utils/toValidUrl';

export class OneOffCheck {
  form = {};
  report = {};
  check = {};
  close = undefined;
  setReady = undefined;
  checks = [];
  _report_url = '';

  constructor({ close, check, setReady, record, checks }) {
    this.setValue(check, 'check');
    this.setValue(record, 'record');
    this.setValue(close, 'close');
    this.setValue(setReady, 'setReady');
    this.setValue(checks, 'checks');
    this.setValue(dataProvider, 'dataProvider');
  }

  get update() {
    return !_.isEmpty(this.check);
  }

  get choices() {
    if (this.update) return this.actions;
    else return this.checks;
  }

  get actions() {
    return this.arrayToObjectWithId(this.check.actions);
  }

  get report_url() {
    return toValidUrl(this._report_url);
  }

  get disableSubmit() {
    if (this.update)
      return (
        _.isEmpty(this.report_url) || _.isUndefined(this.form.action_update)
      );
    else return _.isUndefined(this.form.action_update);
  }

  createCheck = async () => {
    if (_.isEmpty(this.form)) return;
    const response = await this.dataProvider(CREATE, 'fcra_workers', {
      id: this.record.id,
      target: 'one_off_checks',
      targetId: this.form.action_update
    });
    this.handleEnd(response);
  };

  updateCheck = async () => {
    if (_.isEmpty(this.form)) return;
    const response = await this.dataProvider(UPDATE, 'fcra_workers', {
      id: this.record.id,
      target: `one_off_checks`,
      targetId: this.check.id,
      data: {
        action: this.check.actions[this.form.action_update],
        report_url: this.report_url
      }
    });
    this.handleEnd(response);
  };

  handleSubmit = () => {
    this.handleStart();
    this.update ? this.updateCheck() : this.createCheck();
  };

  handleStart = () => {
    this.setReady(false);
  };

  handleEnd = () => {
    this.close();
    this.setReady(true);
  };

  setValue = (value, field) => {
    if (_.isUndefined(value) || _.isNull(value)) return;
    this[field] = value;
  };

  arrayToObjectWithId = array => {
    if (!_.isArray(array)) return;
    const objectWithId = array.map((value, index) => {
      return { id: index, name: value };
    });
    return objectWithId;
  };
}
