import React, { useMemo } from 'react';
import { Label, Node, Tooltip, TooltipDetails } from './styled';
import { getNodeStyles } from './utils';

const TooltipNode = ({ data, styles }) => {
  const { label, tooltipProps, value } = data || {};
  const { listLabel = [], label: stringLabel, position } = tooltipProps || {};

  const nodeStyle = useMemo(() => getNodeStyles(value), [value]);

  return (
    <Node style={{ ...nodeStyle, ...styles }}>
      <Label>{label}</Label>
      {(stringLabel || listLabel.length > 0) && (
        <Tooltip id="tooltip" position={position}>
          {stringLabel}
          {listLabel && (
            <TooltipDetails>
              {listLabel.map(label => (
                <p>{label}</p>
              ))}
            </TooltipDetails>
          )}
        </Tooltip>
      )}
    </Node>
  );
};

export default TooltipNode;
