import React from 'react';
import { SelectInput, ReferenceInput } from 'react-admin';

export const ProviderInput = props => {
  return (
    <ReferenceInput
      label={'Provider'}
      source="provider"
      reference="turn_admin/service_providers"
      sort={{ field: 'name', order: 'ASC' }}
      {...props}
    >
      <SelectInput optionText="name" optionValue="id" translateChoice={false} />
    </ReferenceInput>
  );
};
