import React from 'react';
import { Toolbar } from 'react-admin';
import { SubmitMVRScriptButton } from '.';

export const MVRScriptToolbar = props => (
  <Toolbar {...props}>
    <SubmitMVRScriptButton
      label="Run Script"
      redirect={false}
      submitOnEnter={false}
      handleSubmit={props.handleSubmit}
      values={props.values}
    />
  </Toolbar>
);
