import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SimpleForm, Create, FormDataConsumer, TextInput } from 'react-admin';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';

import { styles } from '../../styles';
import { OverrideInput } from '..';
import { OverrideToolbar } from '.';

export class OverrideLogicForm extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    selectedIds: PropTypes.array.isRequired
  };
  constructor(props) {
    super(props);
    this.state = { form: undefined };
  }
  setForm = form => {
    if (form !== this.state.form) this.setState({ form });
  };
  handleSubmit = save => save;
  render() {
    const { show, close, ...props } = this.props;
    return show ? (
      <div>
        <Button
          mini
          variant="fab"
          color="secondary"
          aria-label="Add"
          onClick={close}
          style={styles.buttons}
        >
          <ClearIcon />
        </Button>
        <Create title="Override" location={{}} {...props}>
          <SimpleForm
            style={styles.form}
            toolbar={
              <OverrideToolbar
                handleSubmit={this.handleSubmit}
                values={this.state.form}
                selectedIds={this.props.selectedIds}
                override_reason={this.state.form}
              />
            }
            handleSubmit={this.handleSubmit}
          >
            <OverrideInput />
            <TextInput source="override_reason" />
            <FormDataConsumer>
              {({ formData }) => {
                this.setForm(formData);
              }}
            </FormDataConsumer>
          </SimpleForm>
        </Create>
      </div>
    ) : null;
  }
}
