import { GET_LIST, CREATE } from 'react-admin';
import dataProvider from 'services/api';
import { CANDIDATE_RESOURCE, RUN_MVR_SCRIPT } from 'providers/turn';

// NOTE: can be turned generic to grab lists
export const getCounties = async (id, perPage) => {
  const resource = 'turn_admin/states';
  const target = 'counties';
  const pagination = { page: 1, perPage };
  const resp = await dataProvider(GET_LIST, resource, {
    id,
    target,
    pagination
  });
  return resp.data;
};

export const REQUEST_COUNTY = 'REQUEST_COUNTY';
export const requestCountyAction = (
  target,
  id,
  data,
  redirectTo,
  callback
) => ({
  type: REQUEST_COUNTY,
  payload: { target, id, data },
  meta: {
    fetch: CREATE,
    resource: CANDIDATE_RESOURCE,
    onSuccess: {
      notification: {
        body: 'County Check Requested Successfully',
        level: 'info'
      },
      redirectTo,
      callback
    },
    onFailure: {
      notification: {
        body: 'Error: County Search was not initiated',
        level: 'warning'
      }
    }
  }
});

export const REQUEST_MVR = 'REQUEST_MVR';
export const requestMVRAction = (target, id, data, redirectTo) => ({
  type: REQUEST_MVR,
  payload: { target, id, data },
  meta: {
    fetch: CREATE,
    resource: CANDIDATE_RESOURCE,
    onSuccess: {
      notification: {
        body: 'MVR Requested Successfully',
        level: 'info'
      },
      redirectTo
    },
    onFailure: {
      notification: {
        body: 'Error: MVR Search was not initiated',
        level: 'warning'
      }
    }
  }
});

export const REQUEST_MVR_SCRIPT = 'REQUEST_MVR_SCRIPT';
export const requestMVRScriptAction = (
  target,
  id,
  targetId,
  data,
  redirectTo
) => ({
  type: REQUEST_MVR_SCRIPT,
  payload: { target, id, targetId, data },
  meta: {
    fetch: CREATE,
    resource: RUN_MVR_SCRIPT,
    onSuccess: {
      notification: {
        body: 'MVR Script Requested Successfully',
        level: 'info'
      },
      redirectTo
    },
    onFailure: {
      notification: {
        body: 'Error: There was an error during MVR Script execution',
        level: 'warning'
      }
    }
  }
});
