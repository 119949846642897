import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: 30px;
  bottom: 30px;

  display: flex;
  flex-direction: column;
  column-gap: 8px;

  width: fit-content;

  font-size: 14px;
  font-weight: 600;

  p {
    margin: 0;
  }
`;

export const ColorKeyBoxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

  margin-top: 4px;

  font-size: 12px;
`;

export const ColorKeyBox = styled.div`
  display: grid;
  place-content: center;

  width: 100px;
  padding: 12px 6px;

  border-radius: 4px;
`;
