import { UPDATE, CREATE } from 'react-admin';

export const methodMap = new Map([[UPDATE, 'PUT'], [CREATE, 'POST']]);
const headers = new Headers({ Accept: 'application/json' });

export const getOptions = (type, payload) => {
  const options = { headers };
  switch (type) {
    case UPDATE:
    case CREATE:
      options.body = JSON.stringify(payload.data);
      options.method = methodMap.get(type);
      break;
    default:
      break;
  }
  return options;
};
