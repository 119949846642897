import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
  SelectInput,
  GET_ONE,
  UPDATE,
  FormDataConsumer
} from 'react-admin';
import dataProvider from 'services/api';
import { Button } from '@material-ui/core';
import _ from 'lodash';
import { arrayToObjectWithId } from 'utils';

export const CandidateEditStatusToolbar = props => (
  <Toolbar>
    <SaveButton onClick={props.handleSave} disabled={props.disabled} />
    <Button style={{ color: '#f44336' }} onClick={props.handleClose}>
      Cancel
    </Button>
  </Toolbar>
);

export class CandidateEditStatus extends Component {
  // static contextTypes = {
  //   router: PropTypes.object
  // };
  static propTypes = {
    setEditStatus: PropTypes.func,
    setReady: PropTypes.func,
    id: PropTypes.string
    // report: PropTypes.object
  };
  static styles = {
    container: {
      background: 'white',
      boxShadow:
        '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)'
    }
  };
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      actions: []
    };
    this.dataProvider = dataProvider;
  }
  componentDidMount() {
    this.getActions();
  }
  getActions = async () => {
    this.props.setReady(false);
    const { id } = this.props;
    const resource = 'fcra_workers';
    const action = 'actions';
    const response = await this.dataProvider(GET_ONE, resource, { id, action });
    this.handleResponse(response, 'getActions');
  };
  setActions = actions => {
    if (!_.isObject(actions)) return this.state.actions;
    this.setState({ actions: arrayToObjectWithId(actions) });
  };
  setForm = form => {
    if (form !== this.state.form) this.setState({ form });
  };
  handleResponse = (response, type) => {
    switch (type) {
      case 'getActions':
        if (!_.isObject(response.data)) this.handleClose();
        this.setActions(response.data.actions);
        this.props.setReady(true);
        break;
      case 'handleSubmit':
        if (!_.isObject(response.data)) this.handleClose();
        this.setActions(response.data.actions);
        this.handleClose();
        window.location.reload();
        break;
      default:
        this.handleClose();
        break;
    }
  };
  handleClose = () => {
    this.setState({ formData: {}, actions: [] });
    this.props.setReady(true);
    this.props.setEditStatus();
  };
  handleSave = async () => {
    this.props.setReady(false);
    const { id } = this.props;
    const { actions, form } = this.state;
    const resource = 'fcra_workers';
    const target = 'actions';
    const targetId = actions[form.action].name;
    const note = form.note;
    const data = { note };
    const response = await this.dataProvider(UPDATE, resource, {
      id,
      target,
      targetId,
      data
    });
    this.handleResponse(response, 'handleSubmit');
  };
  getDisabled = () =>
    _.isUndefined(this.state.form.action) ||
    _.isUndefined(this.state.form.note);
  render() {
    return (
      <div style={CandidateEditStatus.styles.container}>
        <SimpleForm
          toolbar={
            <CandidateEditStatusToolbar
              handleSave={this.handleSave}
              handleClose={this.handleClose}
              disabled={this.getDisabled()}
            />
          }
        >
          <SelectInput
            source="action"
            choices={this.state.actions}
            label="Select a new state"
          />
          <TextInput source="note" label="Add a note" />
          <FormDataConsumer>
            {({ formData }) => {
              this.setForm(formData);
            }}
          </FormDataConsumer>
        </SimpleForm>
      </div>
    );
  }
}
