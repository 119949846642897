import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
  UPDATE,
  SelectField,
  FormDataConsumer,
  SelectInput
} from 'react-admin';
import dataProvider from 'services/api';
import { Button } from '@material-ui/core';
import _ from 'lodash';
import { arrayToObjectWithId } from 'utils';

export const CandidateEditDisputeToolbar = props => (
  <Toolbar>
    <SaveButton onClick={props.handleSave} disabled={props.disabled} />
    <Button style={{ color: '#f44336' }} onClick={props.handleClose}>
      Cancel
    </Button>
  </Toolbar>
);

export class CandidateEditDispute extends Component {
  static propTypes = {
    setEditDispute: PropTypes.func,
    setReady: PropTypes.func,
    id: PropTypes.string
  };
  static styles = {
    container: {
      background: 'white',
      boxShadow:
        '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)'
    }
  };
  constructor(props) {
    super(props);
    this.state = {
      form: {}
    };
    this.dataProvider = dataProvider;
  }
  setForm = form => {
    if (form !== this.state.form) this.setState({ form });
  };
  handleResponse = (response, type) => {
    switch (type) {
      case 'handleSubmit':
        if (!_.isObject(response.data)) this.handleClose();
        this.handleClose();
        window.location.reload();
        break;
      default:
        this.handleClose();
        break;
    }
  };
  handleClose = () => {
    this.setState({ formData: {} });
    this.props.setReady(true);
    this.props.setEditDispute();
  };
  handleSave = async () => {
    this.props.setReady(false);
    const { id } = this.props;
    const { form } = this.state;
    const resource = 'fcra_workers_adverse';
    const target = 'dispute';
    const dispute = form.dispute;
    const comment = form.comment;
    const data = { dispute, comment };
    const response = await this.dataProvider(UPDATE, resource, {
      id,
      target,
      data
    });
    this.handleResponse(response, 'handleSubmit');
  };
  getDisabled = () =>
    _.isUndefined(this.state.form.comment) ||
    _.isUndefined(this.state.form.dispute);
  render() {
    return (
      <div style={CandidateEditDispute.styles.container}>
        <SimpleForm
          toolbar={
            <CandidateEditDisputeToolbar
              handleSave={this.handleSave}
              handleClose={this.handleClose}
              disabled={this.getDisabled()}
            />
          }
        >
          <SelectInput
            source="dispute"
            choices={[{ id: 1, name: 'Yes' }, { id: 0, name: 'No' }]}
            label="Dispute?"
          />
          <TextInput source="comment" label="Comments" />
          <FormDataConsumer>
            {({ formData }) => {
              this.setForm(formData);
            }}
          </FormDataConsumer>
        </SimpleForm>
      </div>
    );
  }
}
