import { EXACT_MATCH, NO_MATCH } from './constants';

export const getNodeValue = property => (property || {}).value || 'NA';

export const getOverrideLogicLabelKey = status =>
  NO_MATCH.includes(status)
    ? 'hide'
    : EXACT_MATCH.includes(status)
    ? 'show'
    : 'na';
