import styled from 'styled-components';

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 8px 16px;

  cursor: pointer;

  svg {
    color: rgb(63, 81, 181);
    font-size: 20px;
    padding-right: 0.5em;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }
`;

export const ActionText = styled.p`
  margin: 0;

  font-size: 0.8125rem;
  color: rgb(63, 81, 181);
  text-transform: uppercase;
`;
