import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-admin';
import ArrowBack from '@material-ui/icons/ArrowBack';

const BackButton = ({ history }) => {
  const handleBack = () => {
    history.goBack(); // Go back to the previous page
  };

  return (
    <Button aria-label="Add" onClick={handleBack} label="Back">
      <ArrowBack />
    </Button>
  );
};

export default withRouter(BackButton);
