import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  SimpleForm,
  Create,
  FileInput,
  SelectInput,
  FormDataConsumer
} from 'react-admin';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import S3 from 'aws-s3';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import _ from 'lodash';

import { styles } from '../styles';
import { OneOffCheck } from 'models/OneOffCheck';
import { TextField } from '@material-ui/core';
import { OneOffCheckToolbar } from './oneOffCheck';

const S3config = {
  bucketName: runtimeEnv().REACT_APP_AWS_BUCKET_NAME,
  dirName: encodeURIComponent(runtimeEnv().REACT_APP_AWS_DIRNAME),
  region: runtimeEnv().REACT_APP_AWS_REGION,
  accessKeyId: runtimeEnv().REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: runtimeEnv().REACT_APP_AWS_SECRET_ACCESS_KEY,
  s3Url: 'https://turn-hq.s3.us-east-1.amazonaws.com'
};

const S3Client = new S3(S3config);

export class RequestOneOffCheck extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    setReady: PropTypes.func.isRequired,
    check: PropTypes.object,
    checks: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.model = new OneOffCheck({ ...props });
    this.state = {
      inputError: false,
      fileError: false
    };
  }

  onDrop = files => {
    const file = files[0];

    this.props.setReady(false);
    S3Client.uploadFile(file, file.name.replace(/\.[^/.]+$/, ''))
      .then(async data => {
        file.url = data.location;
        await this.model.setValue(file.url, '_report_url');
        this.props.setReady(true);
      })
      .catch(err => console.error(err));
  };

  handleSubmit = () => {
    const { disableSubmit, report_url, form } = this.model;

    if (_.isUndefined(form.action_update)) this.setState({ inputError: true });
    if (_.isEmpty(report_url)) this.setState({ fileError: true });

    if (!disableSubmit) {
      this.setState({ fileError: false, inputError: false });
      this.model.handleSubmit();
    }
  };

  render() {
    const { close, setReady, ...rest } = this.props;
    const { update } = this.model;
    const fileInputOptions = {
      onDrop: this.onDrop
    };

    return (
      <>
        <Button
          mini
          variant="fab"
          color="secondary"
          aria-label="Add"
          onClick={close}
          style={styles.buttons}
        >
          <ClearIcon />
        </Button>
        <Create {...rest} title="Add Custom Check">
          <SimpleForm
            style={styles.form}
            submitOnEnter={false}
            toolbar={
              <OneOffCheckToolbar
                onClick={this.handleSubmit}
                model={this.model}
              />
            }
          >
            {update && (
              <TextField
                label="Check Type"
                value={this.props.check.name}
                disabled
              />
            )}
            <SelectInput
              source="action_update"
              choices={this.model.choices}
              label={update ? 'Status' : 'Check Type'}
              error={this.state.inputError}
              helperText={
                this.state.inputError ? 'Please select a status' : null
              }
            />
            {update && (
              <>
                <FileInput
                  label="Attach Proof"
                  accept="application/pdf"
                  options={fileInputOptions}
                />
                {this.state.fileError && (
                  <span
                    id="fileError"
                    style={{ color: '#f44336', fontSize: '0.75rem' }}
                  >
                    {'Please upload a file'}
                  </span>
                )}
                {this.model.report_url && (
                  <div style={styles.filePreviewLabel}>
                    <CloudDoneIcon style={styles.filePreviewIcon} />
                    <span>{this.model.report_url}</span>
                  </div>
                )}
              </>
            )}
            <FormDataConsumer>
              {({ formData }) => {
                this.model.setValue(formData, 'form');
              }}
            </FormDataConsumer>
          </SimpleForm>
        </Create>
      </>
    );
  }
}
