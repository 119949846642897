export const CANDIDATE_RESOURCE = 'candidates';
export const CM_CRIMINAL_RESOURCE = 'cm_criminal';
export const CANDIDATE_TRANSLATION = 'fcra_workers';
export const ADVERSE_RESOURCE = 'adverse';
export const ADVERSE_TRANSLATION = 'fcra_workers_adverse';
export const COUNTY_CRIMINAL_RECORDS_RESOURCE = 'criminal_records';
export const COUNTY_CRIMINAL_RECORDS_TRANSLATION =
  'fcra_workers_county_criminal_records';
export const SET_PENDING = 'set_pending';
export const SEND_ADVERSE = 'send_adverse';
export const SEND_PREADVERSE = 'send_preadverse';

export const EDIT_STATUS = 'edit_status';
export const EDIT_NOTE = 'edit_note';
export const EDIT_DISPUTE = 'edit_dispute';
export const RESUME_CHECKS = 'resume_checks';
export const DISABLE_CONSENT_LINK = 'disable_consent_link';
export const MOVE_WORKER_FORWARD = 'move_worker_forward';
export const RUN_MVR_SCRIPT = 'compass_order_reports';
export const RERUN_ADDRESSES = 'rerun_addresses';
export const CM_CRIMINAL = 'monitoring/criminal';

export const RESOURCE_MAP = {};
RESOURCE_MAP[CANDIDATE_RESOURCE] = CANDIDATE_TRANSLATION;
RESOURCE_MAP[ADVERSE_RESOURCE] = ADVERSE_TRANSLATION;
RESOURCE_MAP[
  COUNTY_CRIMINAL_RECORDS_RESOURCE
] = COUNTY_CRIMINAL_RECORDS_TRANSLATION;
RESOURCE_MAP[CM_CRIMINAL_RESOURCE] = CM_CRIMINAL;

export const UNAUTHORIZED = 401;
export const LOGIN_URL = '/login';
export const ContentRangeError = new Error(`
  The Content-Range header is missing in the HTTP Response. 
  The Turn REST data provider expects responses for lists of resources to contain this header with 
  the total number of results to build the pagination. 
  If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header? 
`);

export const DASHBOARD = 'DASHBOARD';
export const REPORT = 'REPORT';
