import React from 'react';
import { Toolbar } from 'react-admin';
import { AddAddressButton } from './';

export const AddAddressToolbar = props => (
  <Toolbar {...props}>
    <AddAddressButton
      label="Add"
      redirect={false}
      submitOnEnter={false}
      handleSubmit={props.handleSubmit}
      values={props.values}
    />
  </Toolbar>
);
