import React, { useEffect, useMemo, useState } from 'react';
import {
  CloseButtonWrapper,
  ContentWrapper,
  DynamicText,
  HeadingContent,
  Modal
} from './styled';
import CloseIcon from '@material-ui/icons/Close';
import { styles } from './styles';
import { SEX_OFFENDER } from './constants';
import DecisionTreeDiagram from '../../components/DecisionTreeDiagram';
import ColorKeyPerMatch from '../../components/DecisionTreeDiagram/ColorKeyPerMatch';
import moment from 'moment';

const DecisionTreeModal = ({ data, isOpen, onCloseButton }) => {
  const [savedPosition, setSavedPosition] = useState({
    x: window.scrollX,
    y: window.scrollY
  });
  const { id, crime_type, record_type, report_token, surface_json } = useMemo(
    () => data || {},
    [data]
  );
  const { surface_match } = surface_json || {};
  const { calculation_date } = surface_match || {};

  const recordType = useMemo(() => {
    if (crime_type && crime_type.includes(SEX_OFFENDER)) return 'SEX OFFENDER';
    return record_type;
  }, [crime_type, record_type]);

  const calculationDate = useMemo(
    () =>
      calculation_date ? moment(calculation_date).format('MM-DD-YYYY') : null,
    [calculation_date]
  );

  const saveCurrentPosition = () =>
    setSavedPosition({ x: window.scrollX, y: window.scrollY });

  useEffect(() => {
    saveCurrentPosition();
    const windowLocation = {
      x: isOpen ? 0 : savedPosition.x,
      y: isOpen ? 0 : savedPosition.y
    };
    window.scroll(windowLocation.x, windowLocation.y);
    document.body.style.overflow = isOpen ? 'hidden' : 'visible';
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen}>
      <ContentWrapper>
        <CloseButtonWrapper>
          <CloseIcon onClick={onCloseButton} style={styles.closeButton} />
        </CloseButtonWrapper>

        <HeadingContent>
          <h2>
            Criminal Algorithm v6 Decision Tree for TURN ID {report_token}
          </h2>
          <h4>
            Record ID: <DynamicText>{id}</DynamicText>
          </h4>
          <h4>
            Record type: <DynamicText>{recordType}</DynamicText>
          </h4>
          {calculationDate && (
            <h4>
              Calculation date: <DynamicText>{calculationDate}</DynamicText>
            </h4>
          )}
        </HeadingContent>

        <DecisionTreeDiagram nodesData={data} />

        <ColorKeyPerMatch />
      </ContentWrapper>
    </Modal>
  );
};

export default DecisionTreeModal;
