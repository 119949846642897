import React, { Component } from 'react';
import auth from '../services/auth';

class Login extends Component {
  componentDidMount() {
    auth.login();
  }
  render() {
    return <div />;
  }
}

export default Login;
