// in src/Menu.js
import React, { createElement } from 'react';
import { connect } from 'react-redux';
import { MenuItemLink, getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import HomeIcon from '@material-ui/icons/Home';
import DraftsIcon from '@material-ui/icons/Drafts';
import { upperFirst } from 'lodash';
import auth from '../services/auth';

export const Menu = ({ resources, onMenuClick }) => (
  <div>
    <MenuItemLink to="/" primaryText="Home" leftIcon={<HomeIcon />} />
    {resources.map((resource, key) =>
      resource.hasList ? (
        <MenuItemLink
          key={key}
          data-type="resource"
          to={`/${resource.name}`}
          primaryText={upperFirst(resource.options.label || resource.name)}
          leftIcon={resource.icon ? createElement(resource.icon) : null}
          onClick={onMenuClick}
        />
      ) : null
    )}
    <MenuItemLink
      to="/"
      primaryText="Logout"
      leftIcon={<LogoutIcon />}
      onClick={() => {
        auth.logout();
      }}
    />
  </div>
);

const mapStateToProps = state => ({
  resources: getResources(state)
});

export default withRouter(connect(mapStateToProps)(Menu));
