import React from 'react';
import { ColorKeyBox, ColorKeyBoxes, Container } from './styled';
import { COLOR_KEY_DATA } from './utils';

const ColorKeyPerMatch = () => {
  return (
    <Container>
      <p>Color key:</p>
      <ColorKeyBoxes>
        {COLOR_KEY_DATA.map(({ label, styles }) => (
          <ColorKeyBox key={label} style={styles}>
            {label}
          </ColorKeyBox>
        ))}
      </ColorKeyBoxes>
    </Container>
  );
};

export default ColorKeyPerMatch;
