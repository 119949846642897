import runtimeEnv from '@mars/heroku-js-runtime-env';

const v1 = /v1$/;
const apiUrl = runtimeEnv().REACT_APP_API_URL;
const adminUrl = apiUrl.replace(v1, 'admin/');
const modelMap = {
  worker: 'partnerworker/'
};
const search = '?search=';

export const getSearchUrl = (model, query) =>
  `${adminUrl}${modelMap[model]}${search}${query}`;
