import { stringify } from 'query-string';

export const getQuery = payload => {
  const query = {};
  if (payload.sort) {
    const { field, order } = payload.sort;
    query.sort = JSON.stringify([field, order]);
  }
  if (payload.pagination) {
    const { page, perPage } = payload.pagination;
    query.range = JSON.stringify([(page - 1) * perPage, page * perPage - 1]);
  }
  if (payload.filter) query.filter = JSON.stringify(payload.filter);
  return stringify(query);
};
