/*
 * Configurable Action Factory
 * Pass in an options object with booleans for edit, list, delete, refresh
 * Output JSX Class is for the <Show /> Component's consumption
 * https://marmelab.com/react-admin/Show.html#actions
 */

import React from 'react';
import {
  ListButton,
  EditButton,
  DeleteButton,
  RefreshButton
} from 'react-admin';
import {
  ActionButton,
  CopyToClipboardButton,
  LinkButton
} from './buttons/index';
import CardActions from '@material-ui/core/CardActions';

const defaults = {
  edit: true,
  list: false,
  delete: true,
  refresh: true,
  viewDashboard: true,
  viewReport: true,
  setPending: false,
  editStatus: false,
  resumeChecks: false,
  sendPreAdverse: false,
  sendAdverse: false,
  editNote: false,
  editDispute: false,
  disableConsentLink: true,
  moveWorkerForward: true,
  rerunAddresses: true
};

export const ActionFactory = options => ({
  basePath,
  data,
  resource,
  setReady,
  setEditStatus,
  setEditNote,
  setEditDispute,
  setDialogOpen,
  setDialogMessage,
  setDialogTitle
}) => {
  const config = Object.assign(defaults, options);
  const {
    edit,
    list,
    delete: del,
    refresh,
    viewDashboard,
    viewReport,
    setPending,
    editStatus,
    resumeChecks,
    sendPreAdverse,
    sendAdverse,
    editNote,
    editDispute,
    disableConsentLink,
    moveWorkerForward,
    rerunAddresses
  } = config;

  const getCandidateEmailId = () => {
    if (!data) return;
    const { report_url } = data || {};
    const splittedReportUrl = report_url ? report_url.split('/') : '';
    return splittedReportUrl[4];
  };

  const getPiiTransferLink = () => {
    const emailId = getCandidateEmailId();
    return `${process.env.REACT_APP_CONSENT_FLOW_URL}/pii/${emailId}/ssn`;
  };

  return (
    <CardActions>
      {edit ? <EditButton basePath={basePath} record={data} /> : null}

      {editStatus && data ? (
        <ActionButton
          type="edit_status"
          title="Edit Status"
          id={data.turn_id}
          show={setEditStatus}
        />
      ) : null}

      {editNote &&
      data &&
      (data.profile_status.toUpperCase() === 'PENDING__FIRST_NOTICE' ||
        data.profile_status.toUpperCase() === 'PENDING__SECOND_NOTICE') ? (
        <ActionButton
          type="edit_note"
          title="Edit Note"
          id={data.turn_id}
          show={setEditNote}
        />
      ) : null}

      {editDispute && data ? (
        <ActionButton
          type="edit_dispute"
          title="Edit Dispute"
          id={data.turn_id}
          show={setEditDispute}
        />
      ) : null}

      {list ? <ListButton basePath={basePath} /> : null}
      {del ? (
        <DeleteButton basePath={basePath} record={data} resource={resource} />
      ) : null}

      {refresh ? <RefreshButton /> : null}
      {rerunAddresses &&
      data &&
      data.profile_status.toUpperCase() === 'REVIEW__IDENTITY' ? (
        <ActionButton
          type="rerun_addresses"
          title="Rerun Addresses"
          id={data.turn_id}
          setReady={setReady}
          setDialogOpen={setDialogOpen}
          setDialogMessage={setDialogMessage}
          setDialogTitle={setDialogTitle}
        />
      ) : null}

      {data ? (
        <CopyToClipboardButton
          label={'Copy PII Transfer Link'}
          onCopied={() => {
            setReady(true);
            setDialogOpen(true);
            setDialogTitle('Success!');
            setDialogMessage('PII Transfer Link copied!');
          }}
          renderIcon
          textToCopy={getPiiTransferLink()}
        />
      ) : null}

      {viewDashboard && data ? (
        <LinkButton
          href={data.uuid}
          type="dashboard"
          title="View in dashboard"
        />
      ) : null}

      {viewReport && data ? (
        <LinkButton href={data.report_url} type="report" title="View report" />
      ) : null}

      {setPending &&
      data &&
      data.profile_status.toUpperCase().startsWith('REVIEW') ? (
        <ActionButton
          type="set_pending"
          title="Set pending"
          id={data.turn_id}
          setReady={setReady}
        />
      ) : null}

      {sendPreAdverse &&
      data &&
      data.profile_status.toUpperCase() === 'PENDING__FIRST_NOTICE' ? (
        <ActionButton
          type="send_preadverse"
          title="Send Preadverse"
          id={data.turn_id}
          setReady={setReady}
          icon
        />
      ) : null}

      {sendAdverse &&
      data &&
      data.profile_status.toUpperCase() === 'PENDING__SECOND_NOTICE' ? (
        <ActionButton
          type="send_adverse"
          title="Send Adverse"
          id={data.turn_id}
          setReady={setReady}
        />
      ) : null}

      {resumeChecks && data ? (
        <ActionButton
          type="resume_checks"
          title="Resume checks"
          id={data.turn_id}
          setReady={setReady}
          setDialogOpen={setDialogOpen}
          setDialogMessage={setDialogMessage}
          setDialogTitle={setDialogTitle}
        />
      ) : null}

      {disableConsentLink && data ? (
        <ActionButton
          type="disable_consent_link"
          title="Disable consent link"
          id={data.turn_id}
          setReady={setReady}
          setDialogOpen={setDialogOpen}
          setDialogMessage={setDialogMessage}
          setDialogTitle={setDialogTitle}
        />
      ) : null}

      {moveWorkerForward && data ? (
        <ActionButton
          type="move_worker_forward"
          title="Move forward"
          id={data.turn_id}
          setReady={setReady}
          setDialogOpen={setDialogOpen}
          setDialogMessage={setDialogMessage}
          setDialogTitle={setDialogTitle}
        />
      ) : null}
    </CardActions>
  );
};
