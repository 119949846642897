import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
  SelectInput,
  required,
  minLength,
  maxLength,
  regex
} from 'react-admin';
import { Row } from 'components';
import { BackButton } from '../../components/buttons';
import {StateInput} from "./view";

const EditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton redirect={ window.location.pathname + "/show"}/>
    <BackButton />
  </Toolbar>
);

const choices = [
  { id: 'true', value: '1', label: 'true' },
  { id: 'not-defined', value: '-1', label: 'not defined' },
  { id: 'false', value: '0', label: 'false' }
];

const choices_manual = [
  { id: 'True', value: 'True', label: 'true' },
  { id: 'False', value: 'False', label: 'false' }
];

const gender_choices = [
  { id: 'M', name: 'Male' },
  { id: 'F', name: 'Female' },
  { id: 'N', name: 'Non-binary' }
];

const profile_status_choices = [
  { id: 'emailed', name: 'emailed' },
  { id: 'processing', name: 'processing' },
  { id: 'review', name: 'review' },
  { id: 'review__identity', name: 'review__identity' },
  { id: 'consent', name: 'consent' },
  { id: 'pending', name: 'pending' },
  { id: 'approved', name: 'approved' },
  { id: 'review__qa', name: 'review__qa' },
  { id: 'review__mvr', name: 'review__mvr' },
  { id: 'review__so', name: 'review__so' },
  { id: 'rejected', name: 'rejected' },
  { id: 'pending__first_notice', name: 'pending__first_notice' },
  { id: 'pending__second_notice', name: 'pending__second_notice' },
  { id: 'withdrawn', name: 'withdrawn' },
  { id: 'initiated', name: 'initiated' }
];

const validateZipCode = regex(/^\d{5}$/, 'Must be a valid Zip Code');
const validatePhoneNumber = regex(/^\d{10,11}$/, 'Must be 10 or 11 characters');
const validateFirstName = [required(), minLength(2), maxLength(100)];
const validateLastName = [required(), minLength(2), maxLength(100)];
const validateMiddleName = [maxLength(1000)];

export const CandidateEdit = props => {
  return (
    <Edit title={'Candidate'} {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <Row>
          <TextInput source="first_name" validate={validateFirstName}/>
          <TextInput source="last_name" validate={validateLastName}/>
          <TextInput source="middle_name" validate={validateMiddleName}/>
          <TextInput source="candidate_email" />
        </Row>
        <Row>
          <TextInput source="ssn" />
          <SelectInput
            source="is_ssn_valid"
            choices={choices}
            optionText="label"
            optionValue="value"
          />
          <SelectInput
            source="is_ssn_deceased"
            choices={choices}
            optionText="label"
            optionValue="value"
          />
          <SelectInput
            source="gender"
            choices={gender_choices}
          />
        </Row>
        <Row>
          <TextInput source="candidate_provided_name" label="Name Entered" />
          <TextInput
            source="public_provided_name"
            label="Public Records Name"
          />
          <TextInput source="zipcode" label="Zipcode" validate={validateZipCode}/>
          <TextInput source="phone_number" label="Phone Number" validate={validatePhoneNumber} />
        </Row>
        <Row>
          <TextInput
            source="candidate_provided_date_of_birth"
            label="DOB Entered"
          />
          <TextInput
            source="public_provided_date_of_birth"
            label="Public Records DOB"
          />
        </Row>
        <Row>
          <TextInput source="drivers_license_number" />
          <StateInput label="Driver License State" source="drivers_license_state" include_prefix={false}/>
        </Row>
        <Row>
          <TextInput source="mvr_driver_full_name" label="MVR Full Name" />
          <TextInput source="mvr_driver_license_dob" label="MVR DOB" />
          <SelectInput
            source="is_mvr_report_updated"
            choices={choices_manual}
            optionText="label"
            optionValue="value"
          />
          <SelectInput
            source="profile_status"
            choices={profile_status_choices}
          />
        </Row>
        <Row>
          <SelectInput
            source="is_manual_background_check"
            choices={choices_manual}
            optionText="label"
            optionValue="value"
          />
        </Row>
      </SimpleForm>
    </Edit>
  );
};
