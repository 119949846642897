import React from 'react';
import {
  Dialog as MaterialDialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core';

export const Dialog = ({ open, message, title, setDialogOpen, buttonOnClick }) => {
  return (
    <MaterialDialog open={open}>
      <DialogTitle>{title ? title : 'Something went wrong'}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="raised"
          onClick={ buttonOnClick ? buttonOnClick : () => {
            setDialogOpen(false);
          } }
        >
          OK
        </Button>
      </DialogActions>
    </MaterialDialog>
  );
};
