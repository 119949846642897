import React, { Component } from 'react';
import {
  DateField,
  Datagrid,
  ReferenceManyField,
  Tab,
  TextField,
  UPDATE,
  FunctionField
} from 'react-admin';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { CM_CRIMINAL_RESOURCE } from 'providers/turn';
import { styles } from '../styles';
import dataProvider from 'services/api';
export class CmActionsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCMActionButtons: false,
      showCMAlertsList: false,
      dialogState: {
        response: null,
        isDialogOpen: false
      }
    };
  }
  showResponseDialog = response => {
    this.setState({
      dialogState: {
        response,
        isDialogOpen: true
      }
    });
  };
  reloadPage = () => {
    window.location.reload();
  };
  handleError = error => {
    if (error.body) {
      this.setState({
        dialogState: {
          Message: error.body.message,
          isDialogOpen: true
        }
      });
    } else {
      this.setState({
        dialogState: {
          Message: 'If this persists, please contact support',
          isDialogOpen: true
        }
      });
    }
  };
  runMatchAction = async (event, id) => {
    const resource = 'monitoring/criminal';
    const target = 'match';
    dataProvider(UPDATE, resource, {
      id,
      target
    })
      .then(response => {
        this.showResponseDialog(response['data']['message']);
      })
      .catch(error => this.handleError(error));
  };
  runNoMatchAction = async (event, id) => {
    const resource = 'monitoring/criminal';
    const target = 'no_match';
    dataProvider(UPDATE, resource, {
      id,
      target
    })
      .then(response => {
        this.showResponseDialog(response['data']['message']);
      })
      .catch(error => this.handleError(error));
  };
  runStopRechecksAction = async (event, id) => {
    const resource = 'monitoring/criminal';
    const target = 'stop_recheck';
    dataProvider(UPDATE, resource, {
      id,
      target
    })
      .then(response => {
        this.showResponseDialog(response['data']['message']);
      })
      .catch(error => this.handleError(error));
  };
  setCandidateStatus = status => {
    if (!this.state.status) this.setState({ status });
  };
  setNeedsCmAction = needs_cm_action => {
    if (!this.state.needs_cm_action) this.setState({ needs_cm_action });
  };
  cannotDoCMActions = () => {
    if (this.state.needs_cm_action === 'true') {
      return false;
    } else {
      return true;
    }
  };
  renderRequestButtons = () => {
    const {
      runMatchAction,
      runNoMatchAction,
      runStopRechecksAction,
      cannotDoCMActions
    } = this;
    return (
      <div>
        <Button
          onClick={event => runMatchAction(event, this.props.record.id)}
          style={styles.buttons}
          disabled={cannotDoCMActions()}
        >
          <AddCircleOutlineIcon /> Match
        </Button>
        <Button
          onClick={event => runNoMatchAction(event, this.props.record.id)}
          style={styles.buttons}
          disabled={cannotDoCMActions()}
        >
          <AddCircleOutlineIcon /> No match
        </Button>
        <Button
          onClick={event => runStopRechecksAction(event, this.props.record.id)}
          style={styles.buttons}
          disabled={cannotDoCMActions()}
        >
          <AddCircleOutlineIcon /> Stop rechecks
        </Button>
      </div>
    );
  };
  CopyToClipboardButton = ({ record }) => {
    const handleCopyToClipboard = () => {
      const textToCopy = JSON.stringify(record.webhook_data, null, 2);
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(textToCopy)
          .then(() => {
            alert('JSON was copied to clipboard');
          })
          .catch(error => {
            console.error('Could not copy text: ', error);
          });
      } else {
        alert('Your browser does not support copying to clipboard');
      }
    };
    return (
      <Button
        label="COPY JSON"
        onClick={handleCopyToClipboard}
        style={styles.altButton}
      >
        COPY JSON
      </Button>
    );
  };
  render() {
    const {
      renderRequestButtons,
      CopyToClipboardButton,
      setCandidateStatus,
      setNeedsCmAction
    } = this;
    const { classes } = this.props;
    return (
      <Tab label="cm actions" {...this.props}>
        <Dialog
          open={this.state.dialogState.isDialogOpen}
          onClose={() =>
            this.setState({ dialogState: { isDialogOpen: false } })
          }
        >
          <div>
            {this.state.dialogState.response && (
              <pre>
                {JSON.stringify(this.state.dialogState.response, null, 2)}
              </pre>
            )}
            <button
              onClick={() => {
                this.setState({ dialogState: { isDialogOpen: false } });
                this.reloadPage();
              }}
            >
              Close
            </button>
          </div>
        </Dialog>
        <FunctionField
          render={record => setCandidateStatus(record.profile_status)}
          addLabel={false}
        />
        {renderRequestButtons()}
        <ReferenceManyField
          reference={CM_CRIMINAL_RESOURCE}
          target="get_alerts"
          label=""
        >
          <Datagrid classes={classes ? { row: classes.row } : null}>
            <FunctionField
              render={record =>
                setNeedsCmAction(record.needs_cm_action.toString())
              }
              addLabel={false}
            />
            <TextField source="status" />
            <TextField source="type" />
            <TextField source="county" />
            <TextField source="state" />
            <TextField source="zipcode" />
            <DateField label="Created at" source="create_at" showTime />
            <DateField label="Updated at" source="updated_at" showTime />
            <CopyToClipboardButton label="JSON" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    );
  }
}
