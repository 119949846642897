import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  SimpleForm,
  Create,
  TextInput,
  BooleanInput,
  FormDataConsumer,
  DateInput
} from 'react-admin';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import { isPlainObject } from 'lodash';

import { styles } from '../../styles';
import { StateInput } from '..';
import { required } from 'utils/validation';
import { AddAddressToolbar, AddressLookup } from '.';
// import { Hidden } from 'components';
import { geosuggestToAddress } from 'utils';

const validate = required();
export class AddAddressForm extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired
  };
  constructor(props) {
    super(props);
    this.state = { form: undefined };
  }
  setForm = form => {
    if (form !== this.state.form) this.setState({ form });
  };
  handleSubmit = save => save;
  handleGeosuggestSelect = geosuggestPayload => {
    if (isPlainObject(geosuggestPayload)) {
      const address = geosuggestToAddress(geosuggestPayload);
      const form = Object.assign(this.state.form, address);
      this.setState({ form });
    }
  };
  render() {
    const { handleGeosuggestSelect } = this;
    const { show, close, ...props } = this.props;
    return show ? (
      <div>
        <Button
          mini
          variant="fab"
          color="secondary"
          aria-label="Add"
          onClick={close}
          style={styles.buttons}
        >
          <ClearIcon />
        </Button>
        <AddressLookup
          style={{ marginTop: '1em' }}
          onSuggestSelect={handleGeosuggestSelect}
        />
        <Create title="Add Address" location={{}} {...props}>
          <SimpleForm
            style={styles.form}
            toolbar={
              <AddAddressToolbar
                handleSubmit={this.handleSubmit}
                values={this.state.form}
              />
            }
            handleSubmit={this.handleSubmit}
          >
            <TextInput source="address1" label="Street" validate={validate} />
            <TextInput source="address2" label="Neighborhood" />
            <TextInput source="city" validate={validate} />
            <TextInput source="county" />
            <StateInput />
            <TextInput source="zip" validate={validate} />
            <TextInput source="zip4" />
            <TextInput source="latitude" />
            <TextInput source="longitude" />
            <DateInput source="first_seen" validate={validate} />
            <DateInput source="last_seen" validate={validate} />
            <BooleanInput
              source="is_current_address"
              label="Set as current"
              validate={validate}
              defaultValue={true}
            />
            <FormDataConsumer>
              {({ formData }) => {
                this.setForm(formData);
              }}
            </FormDataConsumer>
          </SimpleForm>
        </Create>
      </div>
    ) : null;
  }
}
