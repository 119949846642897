import fakeDataProvider from 'ra-data-fakerest';
import { candidateDetail, partnerList, stateList } from '../data';

// https://github.com/marmelab/react-admin/tree/master/packages/ra-data-fakerest
const mockProvider = fakeDataProvider(
  {
    candidates: [candidateDetail],
    partners: partnerList,
    states: stateList
  },
  true
);

export default requestHandler => (type, resource, params) => {
  // if (resource === 'candidates') {
  //   // params.id = 1
  //   return mockProvider(type, resource, params);
  // }
  return requestHandler(type, resource, params);
};
