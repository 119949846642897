import React from 'react';
import { Toolbar } from 'react-admin';
import { SubmitMVRSearchButton } from './';

export const MVRToolbar = props => (
  <Toolbar {...props}>
    <SubmitMVRSearchButton
      label="Request"
      redirect={false}
      submitOnEnter={false}
      handleSubmit={props.handleSubmit}
      values={props.values}
    />
  </Toolbar>
);
