import {
  GET_LIST,
  GET_ONE,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE
} from 'react-admin';
import { ContentRangeError } from './';

export const getTotal = headers =>
  parseInt(
    headers
      .get('content-range')
      .split('/')
      .pop(),
    10
  );

export const handleResponse = (res, type) => {
  const { headers, json } = res;

  switch (type) {
    case GET_LIST:
    case GET_MANY_REFERENCE: {
      if (!headers.has('content-range')) throw ContentRangeError;
      return {
        data: json,
        total: getTotal(headers)
      };
    }
    case GET_ONE:
    case CREATE:
    case UPDATE: {
      if (!json.id) json.id = 1; // TODO: have the server do this stuff
      return { data: json };
    }
    default:
      break;
  }
};
