import React, { useMemo } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import TooltipNode from './nodes/TooltipNode';
import { normalizeNodesData } from './adapters';

const DecisionTreeDiagram = ({ nodesData }) => {
  const normalizedNodesData = useMemo(() => normalizeNodesData(nodesData), [
    nodesData
  ]);

  const {
    address_match,
    applicant,
    criminalHit,
    dob_match,
    exclude_match,
    first_name_match,
    hit,
    last_name_match,
    lookback_match,
    middle_name_match,
    name_match,
    override_logic,
    second_identifier_match,
    ssn_match,
    surface_match,
    turn_match
  } = normalizedNodesData || {};

  return (
    <Tree label={<TooltipNode data={criminalHit} />} lineColor={'#a9a9b7'}>
      <TreeNode label={<TooltipNode data={surface_match} />}>
        <TreeNode label={<TooltipNode data={turn_match} />}>
          <TreeNode label={<TooltipNode data={name_match} />}>
            <TreeNode
              label={
                <TooltipNode
                  styles={{ width: '122px' }}
                  data={first_name_match}
                />
              }
            />
            <TreeNode
              label={
                <TooltipNode
                  styles={{ width: '122px' }}
                  data={last_name_match}
                />
              }
            />
            <TreeNode label={<TooltipNode data={middle_name_match} />} />
          </TreeNode>

          <TreeNode label={<TooltipNode data={second_identifier_match} />}>
            <TreeNode label={<TooltipNode data={address_match} />} />
            <TreeNode
              label={
                <TooltipNode styles={{ width: '97px' }} data={ssn_match} />
              }
            />
            <TreeNode
              label={
                <TooltipNode styles={{ width: '97px' }} data={dob_match} />
              }
            />
          </TreeNode>
        </TreeNode>

        <TreeNode label={<TooltipNode data={lookback_match} />}>
          <TreeNode label={<TooltipNode data={applicant} />} />
          <TreeNode
            label={<TooltipNode styles={{ width: '74px' }} data={hit} />}
          />
        </TreeNode>

        <TreeNode label={<TooltipNode data={exclude_match} />} />
      </TreeNode>

      <TreeNode label={<TooltipNode data={override_logic} />} />
    </Tree>
  );
};

export default DecisionTreeDiagram;
