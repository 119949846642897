import React, { Component, useState } from 'react';
import {
  Tab,
  Datagrid,
  ReferenceManyField,
  TextField,
  UPDATE
} from 'react-admin';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import _ from 'lodash';

import { AddressActionField, AddressTextField } from 'components/fields';
import { AddAddressForm } from 'pages/candidate/view/forms/address';
import { CANDIDATE_RESOURCE } from 'providers/turn';
import SpinnerRectLoader from '../../../../components/SpinnerRectLoader';
import { Dialog } from '../../../../components';
import { styles } from '../styles';
import dataProvider from 'services/api';

export const AddAddressButton = ({ show, onClick }) => {
  return !show ? null : (
    <Button onClick={onClick} style={styles.buttons}>
      <AddCircleOutlineIcon /> Add Address
    </Button>
  );
};

export const AddressActivation = props => {
  if (props.record.active === false) {
    return <span>false</span>;
  }
  return <TextField {...props} source="active" />;
};

export const AddressActivationSwitch = props => {
  return (
    <Switch
      checked={props.record.active}
      onChange={e => props.onChange(e, props.record.id)}
      name="switch"
    />
  );
};

export const AddressList = ({ classes, show, ...props }) => {
  const [state, setState] = useState({ loading: false });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const handleSwitchChange = async (event, id) => {
    setState({ ...state, loading: true });

    const resource = 'fcra_workers';
    const target = 'update_active_address';

    dataProvider(UPDATE, resource, {
      id,
      target
    })
      .then(response =>
        handleResponse(response, response.data.title, response.data.message)
      )
      .catch(error => handleError(error));
  };

  const handleResponse = (response, title, message) => {
    setState({ ...state, loading: false });
    if (!_.isNil(response)) {
      setDialogOpen(true);
      setDialogTitle(title);
      setDialogMessage(message);
    } else {
      window.location.reload();
    }
  };

  const handleError = error => {
    setState({ ...state, loading: false });
    setDialogOpen(true);
    if (error.body) {
      setDialogMessage(
        <span>
          {error.body.message && <p>{`Message: ${error.body.message}`}</p>}
          {error.body.request_id && (
            <p>{`Request id: ${error.body.request_id}`}</p>
          )}
        </span>
      );
    } else {
      setDialogMessage('If this persists, please contact support');
    }
  };

  const reloadPage = () => {
    window.location.reload();
  };

  return !show ? null : (
    <>
      <Dialog
        message={dialogMessage}
        title={dialogTitle}
        open={dialogOpen}
        setDialogOpen={setDialogOpen}
        buttonOnClick={reloadPage}
      />
      {state.loading && <SpinnerRectLoader />}
      <ReferenceManyField
        reference={CANDIDATE_RESOURCE}
        target="addresses"
        label=""
        {...props}
      >
        <Datagrid classes={classes}>
          <AddressActivationSwitch onChange={handleSwitchChange} />
          <AddressActivation source="active" />
          <AddressTextField source="address1" label="Street" />
          <AddressTextField source="city" />
          <AddressTextField source="state" />
          <AddressTextField source="zip" />
          <AddressTextField source="county" />
          <AddressTextField source="first_seen" />
          <AddressTextField source="last_seen" />
          <AddressActionField source="id" label="" />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};

export class AddressesTab extends Component {
  state = { showAddressForm: false };
  toggleForm = () => {
    this.setState({ showAddressForm: !this.state.showAddressForm });
  };
  render() {
    const { toggleForm, props } = this;
    const { showAddressForm } = this.state;
    return (
      <Tab label="addresses" {...props}>
        <AddAddressButton show={!showAddressForm} onClick={toggleForm} />
        <AddressList show={!showAddressForm} {...props} />
        <AddAddressForm show={showAddressForm} close={toggleForm} />
      </Tab>
    );
  }
}
