import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Labeled } from 'react-admin';
import _ from 'lodash';

const textStyle = {
  display: 'block',
  fontSize: '0.875rem',
  lineHeight: '1.46429em'
};

export const Checks = ({ actions }) =>
  actions.map(value =>
    _.isString(value) ? (
      <span style={textStyle}> {_.upperFirst(_.lowerCase(value))} </span>
    ) : null
  );
Checks.propTypes = {
  actions: PropTypes.array
};

class PendingChecksField extends Component {
  static propTypes = {
    record: PropTypes.object
  };
  render() {
    if (!this.props.record) return null;
    if (!Array.isArray(this.props.record.actions)) return null;
    if (_.isEmpty(this.props.record.actions)) return null;

    return (
      <Labeled label="Pending checks">
        <Checks actions={this.props.record.actions} />
      </Labeled>
    );
  }
}

export default PendingChecksField;
