import React from 'react';
import {
  Tab,
  EmailField,
  FileField,
  Show,
  TabbedShowLayout,
  RichTextField,
  Datagrid,
  ArrayField,
  BooleanField
} from 'react-admin';
import { ConditionalTextField as TextField } from 'components/fields';
import { TextField as TextF } from 'react-admin';

const ReasonsField = ({ record }) => (
  <ul>
    {record.reasons.map(item => (
      <li key={item.reason}>{item.reason}</li>
    ))}
  </ul>
);
ReasonsField.defaultProps = {
  addLabel: true
};

export const SummaryTab = props => (
  <Show {...props} title=" ">
    <TabbedShowLayout>
      <Tab label="basic information" {...props}>
        <TextField source="turn_id" label="Turn Id" />
        <TextField source="id" label="ID" />
        <TextField source="display_name" label="Name" />
        <EmailField source="candidate_email" label="Email" />
        <TextField source="profile_status" label="Profile Status" />
        <TextF source="adverse.status" label="Adverse Status" />
        <TextField source="zipcode" label="Zipcode" />
        <TextF source="location.city" label="City" />
        <TextF source="location.state" label="State" />
        <TextField source="report_url" label="Report URL" />
        <TextField source="note" label="Reject Reason" />
        <FileField
          source="adverse.preadverse_attachments"
          src="url"
          title="name"
          label="Preadverse Attachments"
          target="_blank"
          addLabel={true}
        />
        <FileField
          source="adverse.adverse_attachments"
          src="url"
          title="name"
          label="Adverse Attachments"
          target="_blank"
          addLabel={true}
        />
        <ArrayField source="adverse.specific_reasons" label="Rejected Reasons">
          <Datagrid addLabel={false}>
            <TextF source="reason" addLabel={false} />
          </Datagrid>
        </ArrayField>
        <BooleanField source="adverse.dispute" label="Dispute" />
        <TextF source="adverse.comments" label="Comments" />
      </Tab>
      <Tab label="Pre-Adverse Notice" path="preadverse">
        <RichTextField source="adverse.preadverse" addLabel={false} />
      </Tab>
      <Tab label="Adverse Notice" path="adverse">
        <RichTextField source="adverse.adverse" addLabel={false} />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
