import { fetchUtils } from 'react-admin';
import { turnAPI } from 'providers/turn';
import mockResponse from './mock';

const httpClient = (url, options = {}) => {
  options.user = {
    authenticated: true,
    token: `Bearer ${localStorage.access_token}`
  };
  return fetchUtils.fetchJson(url, options);
};
const dataProvider = turnAPI(httpClient);

export default mockResponse(dataProvider);
