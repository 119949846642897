import React, { useEffect, useState } from 'react';
import { ActionText, ActionsContainer } from './styled';
import FileCopy from '@material-ui/icons/FileCopy';

const CopyToClipboardButton = ({
  label,
  onCopied,
  renderIcon = false,
  textToCopy
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const onClick = () => {
    if (!navigator.clipboard) return;
    navigator.clipboard.writeText(textToCopy);
    setIsCopied(true);
  };

  useEffect(() => {
    if (!isCopied) return;

    onCopied && onCopied();
    setIsCopied(false);
  }, [isCopied]);

  return (
    <ActionsContainer onClick={onClick}>
      {renderIcon && <FileCopy />}
      <ActionText>{label}</ActionText>
    </ActionsContainer>
  );
};

export default CopyToClipboardButton;
