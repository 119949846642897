import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  ShowButton
} from 'react-admin/lib';
import { AdverseFilter } from './';
import _ from 'lodash';

export const DateFieldAA = props => {
  if (props.record.adverse && props.record.adverse[props.source]) {
    if (_.isNil(props.record.adverse[props.source])) {
      return <span>-</span>;
    }
    return <DateField {...props} source={`adverse.${props.source}`} />;
  }
  return <span>-</span>;
};

export const AdverseList = props => (
  <List title="Adverse" filters={<AdverseFilter />} {...props}>
    <Datagrid>
      <TextField source="display_name" label="Name" />
      <TextField source="adverse.preadverse" label="Pre-Adverse Action" />
      <DateFieldAA source="pa_date_sent" label="Date PA Sent" />
      <TextField source="adverse.adverse" label="Adverse Action" />
      <DateFieldAA source="aa_date_sent" label="Date AA Sent" />
      <TextField source="partner_name" label="Partner" />
      <TextField source="adverse.status" label="Status" />
      <BooleanField source="adverse.dispute" label="Dispute" />
      <ShowButton label="Show" />
    </Datagrid>
  </List>
);
