import React from 'react';
import { Toolbar } from 'react-admin';
import { SubmitOneOffCheckButton } from './';

export const OneOffCheckToolbar = props => (
  <Toolbar {...props}>
    <SubmitOneOffCheckButton
      label="Request"
      redirect={false}
      submitOnEnter={false}
      onClick={props.onClick}
      values={props.values}
      {...props}
    />
  </Toolbar>
);
