import React, { Component, useState } from 'react';
import {
  BooleanField,
  Datagrid,
  FunctionField,
  ReferenceManyField,
  Tab,
  TextField,
  UPDATE
} from 'react-admin';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { OverrideLogicForm } from 'pages/candidate/view/forms/county';
import { CANDIDATE_RESOURCE } from 'providers/turn';
import SpinnerRectLoader from '../../../../components/SpinnerRectLoader';
import { Dialog } from '../../../../components';
import dataProvider from 'services/api';
import { styles } from '../styles';
import _ from 'lodash';
import DecisionTreeModal from '../modals/DecisionTreeModal';
import { CriminalVersionWrapper, DiagramSubtaskIconWrapper } from './styled';
import { ReactComponent as DiagramSubtaskIcon } from 'svgs/diagram-subtask.svg';

export const CriminalOverrideLogicButton = ({ show, onClick, disabled }) => {
  return !show ? null : (
    <Button onClick={onClick} style={styles.buttons} disabled={disabled}>
      <AddCircleOutlineIcon /> Override Logic
    </Button>
  );
};

export const CountyCriminalRecordActivation = props => {
  if (props.record.active === false) {
    return <span>false</span>;
  }
  return <TextField {...props} source="active" />;
};

export const CriminalRecordActivationSwitch = props => {
  return (
    <Switch
      checked={props.record.active}
      onChange={e => props.onChange(e, props.record.id)}
      name="switch"
    />
  );
};

export const CriminalRecordsList = ({
  classes,
  show,
  selectedIds,
  setSelectedIds,
  ...props
}) => {
  const [state, setState] = useState({ loading: false });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [decisionTreeModalOpen, setDecisionTreeModalOpen] = useState(false);
  const [decisionTreeData, setDecisionTreeData] = useState(null);

  const handleSwitchChange = async (event, id) => {
    setState({ ...state, loading: true });

    const resource = 'fcra_workers';
    const target = 'update_worker_criminal_record';

    dataProvider(UPDATE, resource, {
      id,
      target
    })
      .then(response =>
        handleResponse(response, response.data.title, response.data.message)
      )
      .catch(error => handleError(error));
  };

  const handleSelect = id => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleResponse = (response, title, message) => {
    setState({ ...state, loading: false });
    if (!_.isNil(response)) {
      setDialogOpen(true);
      setDialogTitle(title);
      setDialogMessage(message);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      window.location.reload();
    }
  };

  const handleError = error => {
    setState({ ...state, loading: false });
    setDialogOpen(true);
    if (error.body) {
      this.props.setDialogMessage(
        <span>
          {error.body.message && <p>{`Message: ${error.body.message}`}</p>}
          {error.body.request_id && (
            <p>{`Request id: ${error.body.request_id}`}</p>
          )}
        </span>
      );
    } else {
      this.props.setDialogMessage('If this persist please contact support');
    }
  };

  return !show ? null : (
    <>
      <DecisionTreeModal
        data={decisionTreeData}
        isOpen={decisionTreeModalOpen}
        onCloseButton={() => setDecisionTreeModalOpen(false)}
      />
      <Dialog
        message={dialogMessage}
        title={dialogTitle}
        open={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
      {state.loading && <SpinnerRectLoader />}
      <Tab label="County" {...props}>
        <ReferenceManyField
          reference={CANDIDATE_RESOURCE}
          target="worker_county_criminal_records"
          label=""
          {...props}
        >
          <Datagrid>
            <CriminalRecordActivationSwitch onChange={handleSwitchChange} />
            <CountyCriminalRecordActivation source="active" />
            <FunctionField
              label=""
              render={record => (
                <input
                  type="checkbox"
                  checked={selectedIds.includes(record.id)}
                  onChange={() => handleSelect(record.id)}
                />
              )}
            />
            <FunctionField
              label="Override"
              render={record => {
                if (_.isNil(record.override_surface_logic)) {
                  return 'NULL';
                }
                return record.override_surface_logic ? 'SHOW' : 'HIDE';
              }}
            />

            <BooleanField source="records_match" label="records_match" />
            <BooleanField source="turn_match" label="turn_match" />

            <FunctionField
              label="criminal_version"
              source="criminal_version"
              render={record => {
                const criminalVersion = record.criminal_version;
                const isV6 = +criminalVersion >= 6;
                return (
                  <CriminalVersionWrapper>
                    <p>{criminalVersion}</p>
                    {isV6 && (
                      <DiagramSubtaskIconWrapper
                        onClick={() => {
                          setDecisionTreeData(record);
                          setDecisionTreeModalOpen(true);
                        }}
                      >
                        <DiagramSubtaskIcon />
                      </DiagramSubtaskIconWrapper>
                    )}
                  </CriminalVersionWrapper>
                );
              }}
            />

            <TextField source="create_ts" label="create_ts" />
            <TextField source="id" label="ID" />
            <TextField source="recheck_id" label="recheck_id" />
            <TextField source="report_token" />
            <TextField source="case_number" />
            <TextField source="state" />
            <TextField source="county" />
            <TextField source="crime_type" />
            <TextField
              source="offense_description1"
              style={{ width: '300px' }}
            />
            <TextField source="disposition" />
            <TextField source="disposition_date" />
            <TextField source="offense_date" />
            <TextField source="address_match" />
            <TextField source="date_of_birth_match" />
            <TextField source="first_name_match" />
            <TextField source="last_name_match" />
            <TextField source="middle_name_match" />
            <TextField source="ssn_match" />
            <TextField source="gender_match" />
            <TextField source="name_match" />
            <TextField source="race_match" />
            <TextField source="suspect_first_name" />
            <TextField source="suspect_last_name" />
            <TextField source="suspect_middle_name" />
            <TextField source="record_type" />
            <TextField source="dob" />
            <TextField source="fips" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </>
  );
};

export class CriminalRecordsTab extends Component {
  state = { showOverrideForm: false, selectedIds: [] };
  toggleForm = () => {
    this.setState({ showOverrideForm: !this.state.showOverrideForm });
  };
  cannotOverride = () => {
    return this.state.selectedIds.length === 0;
  };

  render() {
    const { toggleForm, props, cannotOverride } = this;
    const { showOverrideForm } = this.state;

    const setSelectedIds = selectedIds => {
      this.setState({ selectedIds });
    };

    return (
      <Tab label="County" {...props}>
        <CriminalOverrideLogicButton
          show={!showOverrideForm}
          onClick={toggleForm}
          disabled={cannotOverride()}
        />
        <CriminalRecordsList
          show={!showOverrideForm}
          selectedIds={this.state.selectedIds}
          setSelectedIds={setSelectedIds}
          {...props}
        />
        <OverrideLogicForm
          show={showOverrideForm}
          close={toggleForm}
          selectedIds={this.state.selectedIds}
        />
      </Tab>
    );
  }
}
