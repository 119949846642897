import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Labeled } from 'react-admin';
import _ from 'lodash';

const textStyle = {
  display: 'block',
  fontSize: '0.875rem',
  lineHeight: '1.46429em'
};

const headerCellStyle = {
  padding: '8px 12px',
  textAlign: 'left'
};

const cellStyle = {
  padding: '8px 12px',
  borderBottom: '1px solid rgba(224, 224, 224, 1)'
};

export const DocumentRow = ({ document_data }) =>
  document_data.map(value => {
    return (
      <tr>
        <td style={cellStyle}>
          <span style={textStyle}>{value.type}</span>
        </td>
        <td style={cellStyle}>
          <span style={textStyle}>
            <a href={value.url}>Download {value.type}</a>
          </span>
        </td>
      </tr>
    );
  });

export const DocumentTableHeader = () => {
  return (
    <tr>
      <th style={headerCellStyle}>
        <span style={textStyle}>Doc Type</span>
      </th>
      <th style={headerCellStyle}>
        <span style={textStyle}>Doc URL</span>
      </th>
    </tr>
  );
};

DocumentRow.propTypes = {
  document_data: PropTypes.array
};

class DocumentTable extends Component {
  static propTypes = {
    record: PropTypes.object
  };

  render() {
    if (!this.props.record) return null;
    if (!Array.isArray(this.props.record.documents)) return null;
    if (_.isEmpty(this.props.record.documents)) return null;

    return (
      <Labeled label="Partner Worker Documents">
        <table>
          <DocumentTableHeader />
          <DocumentRow document_data={this.props.record.documents} />
        </table>
      </Labeled>
    );
  }
}

export default DocumentTable;
