export const styles = {
  row: {
    '& button': {
      opacity: 0,
      marginRight: '10px',
      color: '#2b98f0'
    },
    '&:hover': {
      '& button': {
        opacity: 1,
        background: 'none'
      }
    }
  },
  form: {
    marginTop: '2em',
    float: 'left'
  },
  buttons: {
    margin: '1em',
    float: 'right'
  },
  filePreviewLabel: {
    alignItems: 'center',
    display: 'flex'
  },
  filePreviewIcon: {
    marginRight: '5px'
  }
};
