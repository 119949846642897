import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Labeled } from 'react-admin';
import _ from 'lodash';

const textStyle = {
  display: 'block',
  fontSize: '0.875rem',
  lineHeight: '1.46429em'
};

const headerCellStyle = {
  padding: '8px 12px',
  textAlign: 'left'
};

const cellStyle = {
  padding: '8px 12px',
  borderBottom: '1px solid rgba(224, 224, 224, 1)'
};

export const StatusRow = ({ status_history }) =>
  status_history.map(value => {
    const unixTimestamp = value.modifiedAt;
    const dateObj = new Date(unixTimestamp * 1000);
    const dateString = dateObj.toString();
    const shortDateString = dateString.slice(0, 24);

    return (
      <tr>
        <td style={cellStyle}>
          <span style={textStyle}>{value.status}</span>
        </td>
        <td style={cellStyle}>
          <span style={textStyle}>{value.originalStatus}</span>
        </td>
        <td style={cellStyle}>
          <span style={textStyle}>{shortDateString}</span>
        </td>
        <td style={cellStyle}>
          <span style={textStyle}>{value.author}</span>
        </td>
      </tr>
    );
  });

export const StatusTableHeader = () => {
  return (
    <tr>
      <th style={headerCellStyle}>
        <span style={textStyle}>Status</span>
      </th>
      <th style={headerCellStyle}>
        <span style={textStyle}>Previous Status</span>
      </th>
      <th style={headerCellStyle}>
        <span style={textStyle}>Modified at</span>
      </th>
      <th style={headerCellStyle}>
        <span style={textStyle}>Author</span>
      </th>
    </tr>
  );
};

StatusRow.propTypes = {
  status_history: PropTypes.array
};

class StatusHistoryTable extends Component {
  static propTypes = {
    record: PropTypes.object
  };

  render() {
    if (!this.props.record) return null;
    if (!Array.isArray(this.props.record.status_history)) return null;
    if (_.isEmpty(this.props.record.status_history)) return null;

    return (
      <Labeled label="Status History">
        <table>
          <StatusTableHeader />
          <StatusRow status_history={this.props.record.status_history} />
        </table>
      </Labeled>
    );
  }
}

export default StatusHistoryTable;
