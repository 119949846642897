import React, { Component } from 'react';
import history from '../settings/history';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import './styles/callback.css';

class Callback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false
    };
  }
  redirectToLogin = () => {
    history.replace('/');
    window.location.reload();
  };
  componentDidMount() {
    setTimeout(() => this.setState({ ready: true }), 5000);
  }
  render() {
    return (
      <Card>
        <CardContent>
          <div className="container">
            {this.state.ready ? (
              <div>
                <p>
                  It seems we are unable to identify you, please try with a
                  different account.
                </p>
                <Button
                  variant="raised"
                  color="primary"
                  onClick={this.redirectToLogin}
                >
                  Log In
                </Button>
              </div>
            ) : (
              <div>
                <LinearProgress />
                <br />
                Loading...
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default Callback;
