import styled, { css } from 'styled-components';

const modalIsOpenStyles = css`
  opacity: 1;
  z-index: 1500;
`;

const modalIsClosedStyles = css`
  opacity: 0;
  z-index: -50;
`;

const setModalStatusStyles = props => {
  return props.isOpen ? modalIsOpenStyles : modalIsClosedStyles;
};

export const Modal = styled.div`
  position: absolute;
  inset: 0px;

  display: grid;
  place-items: center;

  width: 100vw;
  height: 100vh;
  padding: 14px;

  background-color: rgba(0, 0, 0, 0.7);

  box-sizing: border-box;

  transition: all 300ms ease-in-out;

  ${setModalStatusStyles}
`;

export const ContentWrapper = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  padding: 24px;

  background-color: white;
  border-radius: 4px;

  box-sizing: border-box;
`;

export const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 100%;

  font-size: 20px;

  svg {
    &:hover {
      color: #3f51b5;
    }
  }
`;

export const HeadingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  width: 100%;
  margin: 10px 0 30px;

  h2 {
    text-decoration-line: underline;
  }

  h2,
  h4 {
    margin: 0;
  }
`;

export const DynamicText = styled.span`
  font-weight: 400;
`;
