import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

class BackButton extends Component {
  static propTypes = {
    buttonType: PropTypes.string
  };
  static contextTypes = {
    router: PropTypes.object
  };
  handleClick = () => {
    this.context.router.history.goBack();
  };
  render() {
    return (
      <Button style={{ color: '#f44336' }} onClick={this.handleClick}>
        Cancel
      </Button>
    );
  }
}

export default BackButton;
