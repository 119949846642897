import { CREATE } from 'react-admin';
import { CANDIDATE_RESOURCE } from 'providers/turn';

const target = 'addresses';
export const ADDRESS_ADD = 'ADDRESS_ADD';
export const addressAdd = (id, data, redirectTo) => ({
  type: ADDRESS_ADD,
  payload: { id, data, target },
  meta: {
    fetch: CREATE,
    resource: CANDIDATE_RESOURCE,
    subResource: 'addresses',
    refresh: true
  },
  onSuccess: {
    notification: {
      body: 'ra.notification.created',
      level: 'info',
      messageArgs: {
        smart_count: 1
      }
    },
    redirectTo
  }
});
