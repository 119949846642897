import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  SimpleForm,
  TextInput,
  SelectInput,
  Create,
  FormDataConsumer
} from 'react-admin';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import validateUSDriversLicense from 'validate-us-drivers-license/src/validate-drivers-license';

import { styles } from '../styles';
import { StateInput } from '../';
import { MVRToolbar } from './mvr';
import { TurnDateInput } from 'components/inputs';
import { required } from 'utils/validation';

const validate = required();
export const licenseValidation = (value, { state }) => {
  const abbr = state ? state.split('-')[1] : null;
  const { valid, reasons } = validateUSDriversLicense(value, abbr);
  return valid ? undefined : reasons[0];
};
const validateLicense = [validate, licenseValidation];

export class RequestMVR extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    location: PropTypes.object
  };
  static defaultProps = {
    location: {}
  };
  constructor(props) {
    super(props);
    this.state = { state: undefined, counties: [] };
  }
  setCurrentState(state) {
    if (state !== this.state.state) this.setState({ state });
  }
  setForm = form => {
    if (form !== this.state.form) this.setState({ form });
  };
  handleSubmit = save => {
    return save;
  };
  render() {
    const { show, close } = this.props;
    return !show ? null : (
      <div>
        <Button
          mini
          variant="fab"
          color="secondary"
          aria-label="Add"
          onClick={close}
          style={styles.buttons}
        >
          <ClearIcon />
        </Button>
        <Create location={{}} {...this.props} title="Request County Criminal">
          <SimpleForm
            style={styles.form}
            toolbar={
              <MVRToolbar
                handleSubmit={this.handleSubmit}
                values={this.state.form}
              />
            }
            handleSubmit={this.handleSubmit}
            submitOnEnter={false}
          >
            <TextInput
              source="first_name"
              validate={validate}
              disabled={true}
            />
            <TextInput source="last_name" validate={validate} disabled={true} />
            {/* The type of element was changed to not allow editing: TurnDateInput --> TextInput */}
            <TextInput source="date_of_birth" label="DOB" disabled={true} />
            <SelectInput
              source="gender"
              choices={[{ id: 'M', name: 'Male' }, { id: 'F', name: 'Female' }]}
              disabled={true}
            />
            <StateInput
              label="Driver License State"
              validate={validate}
              disabled={true}
            />
            <FormDataConsumer>
              {({ formData }) => {
                this.setCurrentState(formData.state);
                this.setForm(formData);
              }}
            </FormDataConsumer>
            <TextInput
              source="drivers_license_number"
              label="Driver License Number"
              validate={validateLicense}
              disabled={true}
            />
          </SimpleForm>
        </Create>
      </div>
    );
  }
}
