import styled from 'styled-components';
import { TOOLTIP_POSITION } from './utils';

export const Node = styled.div`
  position: relative;

  height: fit-content;
  padding: 20px 8px;

  font-size: 14px;

  border-radius: 4px;

  transition: all 300ms ease-in-out;

  &:hover {
    #tooltip {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const Label = styled.p`
  margin: 0;

  font-weight: 600;
`;

const setTooltipPositionStyles = props => {
  return TOOLTIP_POSITION[props.position];
};

export const Tooltip = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: max-content;
  max-width: 350px;
  max-height: 250px;
  padding: 8px 12px;

  font-size: 12px;
  text-align: start;
  color: white;
  background-color: black;
  line-height: 1.375;
  font-weight: 400;
  white-space: pre-wrap;

  border-radius: 4px;
  z-index: 50;

  transition: all 300ms ease-in-out;

  ${setTooltipPositionStyles}
`;

export const TooltipDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  overflow: auto;

  p {
    margin: 0;
  }
`;
