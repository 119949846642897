import {
  GET_LIST,
  GET_ONE,
  GET_MANY_REFERENCE,
  UPDATE,
  CREATE
} from 'react-admin';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import _ from 'lodash';

import { RESOURCE_MAP } from './const';
import { getQuery } from './getQuery';

const apiUrl = runtimeEnv().REACT_APP_API_URL;

export const mapResource = resource =>
  RESOURCE_MAP[resource] ? RESOURCE_MAP[resource] : resource;

export const getUrl = (type, resource, payload) => {
  const { id, target, targetId, action } = payload;
  resource = mapResource(resource);
  switch (type) {
    case GET_LIST:
    case GET_MANY_REFERENCE:
      const query = getQuery(payload);
      return target
        ? query
          ? `${apiUrl}/${resource}/${id}/${target}?${query}`
          : `${apiUrl}/${resource}/${id}/${target}`
        : `${apiUrl}/${resource}?${query}`;
    case GET_ONE:
      if (action) return `${apiUrl}/${resource}/${id}/${action}`;
      return `${apiUrl}/${resource}/${id}`;
    case UPDATE:
      if (!target) return `${apiUrl}/${resource}/${id}`;
      if (!targetId && !action) return `${apiUrl}/${resource}/${id}/${target}`;
      if (!targetId) return `${apiUrl}/${resource}/${id}/${target}/${action}`;
      if (!action) return `${apiUrl}/${resource}/${id}/${target}/${targetId}`;
      return `${apiUrl}/${resource}/${id}/${target}/${targetId}/${action}`;
    case CREATE:
    default:
      if (targetId) return `${apiUrl}/${resource}/${id}/${target}/${targetId}`;
      if (target) return `${apiUrl}/${resource}/${id}/${target}`;
      return resource;
  }
};
