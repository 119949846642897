import React from 'react';
import { SelectInput, ReferenceInput } from 'react-admin';

export const StateInput = props => {
  const { record } = props;
  const label = props.label || 'State';
  const source = props.source || 'state';
  const filter = props.filter || {};
  const include_prefix = (props.include_prefix != undefined)?props.include_prefix:true;
  if (include_prefix === false) filter["include_prefix"] = "False";
  const defaultValue =
    record && record.drivers_license_state
      ? `US-${record.drivers_license_state}`
      : null;
  return (
    <ReferenceInput
      label={label}
      source={source}
      reference="turn_admin/states"
      perPage={100}
      sort={{ field: 'name', order: 'ASC' }}
      defaultValue={defaultValue}
      filter={filter}
      {...props}
    >
      <SelectInput optionText="name" optionValue="id" translateChoice={false} />
    </ReferenceInput>
  );
};
