import React, { useState } from 'react';
import {
  Tab,
  Datagrid,
  ReferenceManyField,
  TextField,
  CREATE
} from 'react-admin';
import { CANDIDATE_RESOURCE } from 'providers/turn';
import SpinnerRectLoader from '../../../../components/SpinnerRectLoader';
import { Dialog } from '../../../../components';
import dataProvider from 'services/api';
import _ from 'lodash';

const LicenseField = ({ record, source, hanldeSubmit }) => {
  const [license, setLicense] = useState(record[source]);

  return (
    <input
      onChange={({ target: { value } }) => setLicense(value)}
      value={license}
      onKeyDown={({ keyCode }) =>
        keyCode === 13 ? hanldeSubmit(license, record.id) : null
      }
    />
  );
};

export const MvrLicenseTab = ({ ...props }) => {
  const [state, setState] = useState({ loading: false });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');

  const hanldeSubmit = async (license_number, id) => {
    setState({ ...state, ['loading']: true });

    const resource = 'fcra_workers';
    const target = 'update_worker_mvr_license';
    dataProvider(CREATE, resource, {
      id: id,
      target: target,
      data: {
        license_number
      }
    })
      .then(response =>
        handleResponse(response, response.data.title, response.data.message)
      )
      .catch(error => handleError(error));
  };

  const handleResponse = (response, title, message) => {
    setState({ ...state, ['loading']: false });
    if (!_.isNil(response)) {
      setDialogOpen(true);
      setDialogTitle(title);
      setDialogMessage(message);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      window.location.reload();
    }
  };

  const handleError = error => {
    setState({ ...state, ['loading']: false });
    setDialogOpen(true);
    if (error.body) {
      setDialogMessage(
        <span>
          {error.body.message && <p>{`Message: ${error.body.message}`}</p>}
          {error.body.request_id && (
            <p>{`Request id: ${error.body.request_id}`}</p>
          )}
        </span>
      );
    } else {
      setDialogMessage('If this persist please contact support');
    }
  };

  return (
    <>
      <Dialog
        message={dialogMessage}
        title={dialogTitle}
        open={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
      {state.loading && <SpinnerRectLoader />}
      <Tab label="Licenses" {...props}>
        <ReferenceManyField
          reference={CANDIDATE_RESOURCE}
          target="worker_mvr_license"
          label=""
          {...props}
        >
          <Datagrid>
            <TextField source="id" />
            <LicenseField
              source="license_number"
              name="license_number"
              hanldeSubmit={hanldeSubmit}
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </>
  );
};
