import { BG_COLOR_KEY_MAPPING } from '../../constants';
import {
  EXACT_MATCH,
  NA_MATCH,
  NO_MATCH,
  PARTIAL_MATCH
} from '../../constants';

export const getNodeStyles = value => {
  const {
    dark_amber,
    dark_green,
    dark_grey,
    light_grey,
    red
  } = BG_COLOR_KEY_MAPPING;

  switch (true) {
    case EXACT_MATCH.includes(value):
      return dark_green.styles;

    case PARTIAL_MATCH.includes(value):
      return dark_amber.styles;

    case NO_MATCH.includes(value):
      return red.styles;

    case NA_MATCH.includes(value):
      return dark_grey.styles;

    default:
      return light_grey.styles;
  }
};

const tooltipPositionAfterCommonProps = `
  content: "";
  position: absolute;
  border: 6px solid;
`;

const topPositionAfterCommonStyles = `
  ${tooltipPositionAfterCommonProps}
  top: 100%;
  border-color: black transparent transparent transparent;
`;

const bottomPositionAfterCommonStyles = `
  ${tooltipPositionAfterCommonProps}
  bottom: 100%;
  border-color: transparent transparent black transparent;
`;

export const TOOLTIP_POSITION = {
  top_left: `
    bottom: 110%;
    left: 12px;
    
    &::after {
      left: 12px;
      margin-left: -8px;
      ${topPositionAfterCommonStyles}
    }
  `,
  top_right: `
    bottom: 110%;
    right: 12px;

    &::after {
      right: 12px;
      margin-right: -8px;
      ${topPositionAfterCommonStyles}
    }
  `,
  bottom_left: `
    top: 100%;
    left: 12px;

    &::after {
      left: 12px;
      margin-left: -8px;
      ${bottomPositionAfterCommonStyles}
    }
  `,
  bottom_right: `
    top: 100%;
    right: 12px;

    &::after {
      right: 12px;
      margin-right: -8px;
      ${bottomPositionAfterCommonStyles}
    }
  `
};
