import React from 'react';
import {
  List,
  Datagrid,
  EmailField,
  TextField,
  DateField,
  ShowButton
} from 'react-admin/lib';
import { CandidateFilter } from './';
import { FilteredName } from '../../components/fields';

export const CandidateList = props => {
  return (
    <List
      title="Candidates"
      filters={<CandidateFilter />}
      sort={{ field: 'date_initiated', order: 'DESC' }}
      {...props}
    >
      <Datagrid>
        <DateField source="date_initiated" label="Date" />
        <FilteredName {...props} source="first_name" />
        <FilteredName {...props} source="last_name" />
        <TextField source="partner_name" label="Partner" />
        <EmailField source="candidate_email" label="Email" />
        <TextField source="profile_status" />
        <TextField source="phone_number" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
