import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Assignment from '@material-ui/icons/Assignment';
import ChromeReaderMode from '@material-ui/icons/ChromeReaderMode';
import { DASHBOARD, REPORT } from '../../providers/turn/const';

class LinkButton extends Component {
  static contextTypes = {
    href: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string
  };
  static styles = {
    link: {
      textDecoration: 'none'
    },
    button: {
      color: '#3f51b5',
      fontSize: '0.8125rem'
    },
    icon: {
      color: '#3f51b5',
      fontSize: '20px',
      paddingRight: '0.5em'
    }
  };
  getTitle = title => title.toUpperCase();
  getEmail = () => JSON.parse(localStorage.getItem('profile')).email;
  getIcon = (type, style) => {
    switch (type.toUpperCase()) {
      case DASHBOARD:
        return <ChromeReaderMode style={style} />;
      case REPORT:
        return <Assignment style={style} />;
      default:
        return null;
    }
  };
  getUrl = (type, href) => {
    switch (type.toUpperCase()) {
      case DASHBOARD:
        return `http://turn-prod.herokuapp.com/candidate/view?email=${this.getEmail()}&url=${href}`;
      case REPORT:
        return href;
      default:
        return null;
    }
  };
  render() {
    const { href, type, title } = this.props;
    const { link, button, icon } = LinkButton.styles;

    return (
      <a href={this.getUrl(type, href)} style={link} target="_blank">
        <Button style={button}>
          {this.getIcon(type, icon)}
          {this.getTitle(title)}
        </Button>
      </a>
    );
  }
}

export default LinkButton;
