import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showNotification, refreshView, UPDATE } from 'react-admin';
import Button from '@material-ui/core/Button';
import get from 'lodash/get';
import dataProvider from 'services/api';
import { CANDIDATE_RESOURCE } from 'providers/turn';

const getCandidateId = router => router.route.location.pathname.split('/')[2];

class AddressSetCurrentButton extends Component {
  static propTypes = {
    refreshView: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func
  };
  static contextTypes = {
    router: PropTypes.object
  };
  getCandidateId() {
    return getCandidateId(this.context.router);
  }
  shouldRender() {
    const isCurrentAddress = get(this.props.record, 'is_current_address')
    const active = get(this.props.record, 'active')
    return isCurrentAddress === false && active === true;
  }
  handleClick = () => {
    const { refreshView, record, showNotification } = this.props;
    dataProvider(UPDATE, CANDIDATE_RESOURCE, {
      id: this.getCandidateId(),
      target: 'addresses',
      targetId: record.id,
      action: 'set_current_address'
    })
      .then(() => {
        showNotification('Current address updated');
        refreshView();
      })
      .catch(e => {
        console.error(e);
        showNotification('Error: Current address was not updated', 'warning');
      });
  };
  render() {
    if (!this.shouldRender()) return null;
    return <Button onClick={this.handleClick}>Set as current</Button>;
  }
}

// Export unconnected version of the component to simplify testing
export { AddressSetCurrentButton };
export default connect(
  null,
  { showNotification, refreshView }
)(AddressSetCurrentButton);
