/* Wrapped DateInput API
 * https://material-ui-pickers.firebaseapp.com/demo/datepicker
 */
import React from 'react';
import { DateInput } from 'react-admin-date-inputs';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';

export const TurnDateInput = props => {
  const defaults = { format: 'MM/DD/YYYY' };
  const { options, ...rest } = props;
  const o = options ? Object.assign(defaults, options) : defaults;
  return (
    <DateInput {...rest} options={o} providerOptions={{ utils: MomentUtils }} />
  );
};
