const partners = [
  { id: 1, name: 'Saucey' },
  { id: 2, name: 'HONK' },
  { id: 3, name: 'Bixby' },
  { id: 4, name: 'XYZ' },
  { id: 5, name: 'CarPeople' }
];

export default {
  data: partners,
  total: partners.length
};
