import React from 'react';
import { SelectInput } from 'react-admin';

export const OverrideInput = props => {
  const label = 'Override surface logic';
  const source = props.source || 'state';
  const defaultValue = null;

  const customChoices = [
    { id: 'NULL', name: 'NULL' },
    { id: 'SHOW', name: 'SHOW' },
    { id: 'HIDE', name: 'HIDE' }
  ];

  return (
    <SelectInput
      label={label}
      source={source}
      choices={customChoices}
      defaultValue={defaultValue}
      {...props}
    />
  );
};
