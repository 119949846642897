import styled from 'styled-components';

export const CriminalVersionWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: #3f51b5;
  }
`;

export const DiagramSubtaskIconWrapper = styled.div`
  display: grid;
  place-items: center;

  margin-left: 20px;
  padding: 2.5px;

  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #dfe0e9;
  }
`;
