import { NO_MATCH, OTHER_ID, TOOLTIP_LABELS } from './constants';
import { getNodeValue, getOverrideLogicLabelKey } from './utils';

export const normalizeNodesData = data => {
  if (!data) return null;
  const { other_id, override_surface_logic, surface_match, turn_match } =
    data || {};

  const { exclude_match, lookback_match, name_match, second_identifier_match } =
    data.surface_json || {};

  const {
    ai_response,
    applicant,
    classification_source,
    disposition_classification,
    hit,
    most_restrictive_state
  } = lookback_match || {};

  const { first_name_match, last_name_match, middle_name_match } =
    name_match || {};

  const { address_match, dob_match, ssn_match } = second_identifier_match || {};

  const {
    disposition: ai_disposition,
    model: ai_model,
    plea: ai_plea,
    prompt: ai_prompt,
    status: ai_status
  } = ai_response || {};

  const criminalHitValue =
    override_surface_logic === null ? surface_match : override_surface_logic;

  const criminalHitShouldHide = NO_MATCH.includes(criminalHitValue);

  const isDOBMatchOtherID = dob_match.source && dob_match.source === OTHER_ID;
  const isSSNMatchOtherID = ssn_match.source && ssn_match.source === OTHER_ID;

  return {
    address_match: {
      label: 'AddressMatch',
      tooltipProps: {
        listLabel: [
          `address_selected: ${address_match.address_selected}`,
          `address_source: ${address_match.address_source}`,
          `score: ${address_match.score}`
        ],
        position: 'top_left'
      },
      value: getNodeValue(address_match)
    },
    applicant: {
      label: 'Applicant',
      tooltipProps: {
        listLabel: lookback_match && [
          `lookback_strategy: ${applicant.lookback_strategy}`,
          `state: ${applicant.state}`,
          `years_lookback: ${applicant.years_lookback}`
        ],
        position: 'bottom_left'
      },
      value: getNodeValue(applicant)
    },
    criminalHit: {
      label: `Criminal hit is ${
        !criminalHitShouldHide ? 'reported' : 'not reported'
      }`,
      tooltipProps: {
        label:
          TOOLTIP_LABELS.criminalHit[
            !criminalHitShouldHide ? 'reported' : 'notReported'
          ],
        position: 'bottom_left'
      },
      value: criminalHitValue
    },
    dob_match: {
      label: 'DOBMatch',
      tooltipProps: {
        listLabel: [
          `score: ${dob_match.score}`,
          dob_match.source && `match_source: ${dob_match.source}`,
          other_id && isDOBMatchOtherID && `other_id: ${other_id}`
        ],
        position: 'top_left'
      },
      value: getNodeValue(dob_match)
    },
    exclude_match: {
      label: 'ExcludeMatch',
      tooltipProps: {
        label: `Is this hit within Turn FCRA compliance rules?`,
        position: 'bottom_right'
      },
      value: getNodeValue(exclude_match)
    },
    first_name_match: {
      label: 'FirstNameMatch',
      tooltipProps: {
        listLabel: [
          `name_selected: ${first_name_match.name_selected}`,
          `name_source: ${first_name_match.name_source}`
        ],
        position: 'top_left'
      },
      value: getNodeValue(first_name_match)
    },
    hit: {
      label: 'Hit',
      tooltipProps: {
        listLabel: lookback_match && [
          `lookback_strategy: ${hit.lookback_strategy}`,
          `state: ${hit.state}`,
          `years_lookback: ${hit.years_lookback}`
        ],
        position: 'bottom_right'
      },
      value: getNodeValue(hit)
    },
    last_name_match: {
      label: 'LastNameMatch',
      tooltipProps: {
        listLabel: [
          `name_selected: ${last_name_match.name_selected}`,
          `name_source: ${last_name_match.name_source}`
        ],
        position: 'top_left'
      },
      value: getNodeValue(last_name_match)
    },
    lookback_match: {
      label: 'LookbackMatch',
      tooltipProps: {
        label: `Is this record legally surfaceable depending on when and where it happened?`,
        listLabel: [
          lookback_match &&
            `
            disposition_classification: ${disposition_classification},
            classification_source: ${classification_source},
            most_restrictive_state: ${most_restrictive_state},
          ${
            ai_response
              ? `
            ai_response: {
              disposition: ${ai_disposition},
              model: ${ai_model},
              status: ${ai_status}
              plea: ${ai_plea},
              prompt: ${ai_prompt},
            }
          `
              : ``
          }
          `
        ],
        position: 'bottom_right'
      },
      value: getNodeValue(lookback_match)
    },
    middle_name_match: {
      label: 'MiddleNameMatch',
      tooltipProps: {
        listLabel: [
          `name_selected: ${middle_name_match.name_selected}`,
          `name_source: ${middle_name_match.name_source}`
        ],
        position: 'top_left'
      },
      value: getNodeValue(middle_name_match)
    },
    name_match: {
      label: 'NameMatch',
      tooltipProps: {
        label: `Match on the name identifiers discovered in the criminal hit.`,
        position: 'bottom_left'
      },
      value: getNodeValue(name_match)
    },
    override_logic: {
      label: 'Override Logic',
      tooltipProps: {
        label:
          TOOLTIP_LABELS.override_logic[
            getOverrideLogicLabelKey(override_surface_logic)
          ],
        position: 'bottom_right'
      },
      value: override_surface_logic
    },
    second_identifier_match: {
      label: 'SecondIdentifierMatch',
      tooltipProps: {
        label: `A strong second identifier different from the name.`,
        listLabel: [`total_score: ${second_identifier_match.total_score}`],
        position: 'bottom_left'
      },
      value: getNodeValue(second_identifier_match)
    },
    ssn_match: {
      label: 'SSNMatch',
      tooltipProps: {
        listLabel: [
          `score: ${ssn_match.score}`,
          ssn_match.source && `match_source: ${ssn_match.source}`,
          other_id && isSSNMatchOtherID && `other_id: ${other_id}`
        ],
        position: 'top_left'
      },
      value: getNodeValue(ssn_match)
    },
    surface_match: {
      label: 'SurfaceMatch',
      tooltipProps: {
        label: `Taking in consideration all available data points and matches, should this record be surfaced.`,
        position: 'bottom_left'
      },
      value: surface_match
    },
    turn_match: {
      label: 'TurnMatch',
      tooltipProps: {
        label: `Level of confidence in the discovered hit corresponding to the applicant and not being false positive.`,
        position: 'bottom_left'
      },
      value: turn_match
    }
  };
};
