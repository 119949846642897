import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SaveButton } from 'react-admin';

import { requestMVRAction } from '../../actions';

export class SubmitMVRSearchButtonView extends Component {
  static propTypes = {
    requestMVRAction: PropTypes.func.isRequired
  };
  static contextTypes = {
    router: PropTypes.object
  };
  handleClick = () => {
    const { invalid, handleSubmit, values, requestMVRAction } = this.props;
    const target = 'do_mvr';
    const {
      id,
      first_name,
      last_name,
      date_of_birth,
      gender,
      state,
      drivers_license_number
    } = values;
    const data = {
      first_name,
      last_name,
      gender,
      date_of_birth,
      state,
      drivers_license_number
    };
    const redirectTo = this.context.router.route.location.pathname;
    return handleSubmit(e => {
      if (invalid) return;
      requestMVRAction(target, id, data, redirectTo);
    });
  };
  render() {
    const { handleSubmitWithRedirect, saveWithNote, ...props } = this.props;
    return (
      <SaveButton handleSubmitWithRedirect={this.handleClick} {...props} />
    );
  }
}

export const SubmitMVRSearchButton = connect(
  undefined,
  { requestMVRAction }
)(SubmitMVRSearchButtonView);
