import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Geosuggest from 'react-geosuggest';

import { initGoogleMapsAPI } from 'lib/google-maps';
import './geosuggest.css';

export class AddressLookup extends Component {
  static propTypes = {
    onSuggestSelect: PropTypes.func.isRequired
  };
  constructor(props) {
    super(props);
    this.state = { googleMapsLoaded: !!window.google };
    if (this.state.googleMapsLoaded) return;
    initGoogleMapsAPI().then(() => {
      this.setState({ googleMapsLoaded: true });
    });
  }

  setGeosuggest = el => {
    if (el) this.geosuggest = el;
  };

  onSuggestSelect = payload => {
    this.geosuggest.blur();
    this.props.onSuggestSelect(payload);
  };

  render() {
    const { onSuggestSelect, setGeosuggest } = this;
    const { googleMapsLoaded } = this.state;
    return !googleMapsLoaded ? null : (
      <Geosuggest
        label="Google address lookup"
        ref={setGeosuggest.bind(this)}
        placeholder="Google Maps Address Search"
        country="us"
        types={['geocode']}
        onSuggestSelect={onSuggestSelect.bind(this)}
      />
    );
  }
}
