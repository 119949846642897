import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SaveButton } from 'react-admin';
import { requestCountyAction } from '../../actions';

const OVERRIDE_BULK = 'set_override_bulk';
export class OverrideButtonView extends Component {
  static propTypes = {
    requestCountyAction: PropTypes.func.isRequired
  };
  static contextTypes = {
    router: PropTypes.object
  };
  reloadPage = () => window.location.reload();
  handleClick = () => {
    const {
      invalid,
      handleSubmit,
      values,
      selectedIds,
      requestCountyAction
    } = this.props;
    const target = OVERRIDE_BULK;
    const { id } = values;
    var data = {
      override_option: values.state,
      selected_ids: selectedIds,
      override_reason: values.override_reason
    };
    const redirectTo = this.context.router.route.location.pathname;
    return handleSubmit(e => {
      if (invalid) return;
      requestCountyAction(target, id, data, redirectTo, this.reloadPage);
    });
  };
  render() {
    const { handleSubmitWithRedirect, ...props } = this.props;
    return (
      <SaveButton handleSubmitWithRedirect={this.handleClick} {...props} />
    );
  }
}

export const OverrideButton = connect(
  null,
  { requestCountyAction }
)(OverrideButtonView);
