import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Tab,
  Datagrid,
  ReferenceManyField,
  FunctionField,
  GET_LIST
} from 'react-admin';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import _ from 'lodash';

import dataProvider from 'services/api';
import { CANDIDATE_RESOURCE } from 'providers/turn';
import { RequestOneOffCheck } from '../forms/RequestOneOff';
import {styles} from "../styles";

export class OneOffChecksTab extends Component {
  static propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    check: PropTypes.object,
    setReady: PropTypes.func
  };
  constructor(props) {
    super(props);
    this.state = { showForm: false, check: undefined };
  }

  async componentDidMount() {
    await this.getChecks();
  }

  closeForm = () => {
    if (this.state.showForm)
      this.setState({ showForm: false, check: undefined });
  };
  openForm = () => {
    if (!this.state.showForm) this.setState({ showForm: true });
  };
  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm });
  };
  setCurrentCheck = check => {
    this.setState({ check });
    this.openForm();
  };
  renderEditButton = check => {
    return (
      <Button
        onClick={() => {
          this.setCurrentCheck(check);
        }}
      >
        Edit
      </Button>
    );
  };
  renderRequestButton = () => {
    const { toggleForm } = this;
    const { showForm } = this.state;
    return showForm ? null : (
      <Button onClick={toggleForm} style={styles.buttons}>
        <AddCircleOutlineIcon /> Request Custom Check
      </Button>
    );
  };
  getChecks = async () => {
    const response = await dataProvider(GET_LIST, 'one_off_checks', {
      id: undefined
    });
    this.checks = response.data;
  };

  render() {
    const { closeForm, renderRequestButton, checks } = this;
    const { showForm, check } = this.state;
    const { setReady, ...rest } = this.props;

    return (
      <Tab label="Custom Checks" {...rest}>
        {renderRequestButton()}
        {!showForm ? (
          <ReferenceManyField
            reference={CANDIDATE_RESOURCE}
            target="one_off_checks"
            label=""
            sort={{ field: '_doc', order: 'DESC' }}
          >
            <Datagrid>
              <FunctionField
                render={record => _.startCase(record.name)}
                label="Check"
              />
              <FunctionField
                render={record => _.startCase(record.state)}
                label="Status"
              />
              <FunctionField
                render={record => record.resolution}
                label="Resolution"
              />
              <FunctionField
                render={record => record.report_url}
                label="Link"
              />
              <FunctionField
                render={record => this.renderEditButton(record)}
                label="Check"
              />
            </Datagrid>
          </ReferenceManyField>
        ) : (
          <RequestOneOffCheck
            close={closeForm}
            check={check}
            checks={checks}
            {...this.props}
          />
        )}
      </Tab>
    );
  }
}
