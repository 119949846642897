import React, { Component } from 'react';
import { Tab } from 'react-admin';
import { RequestMVRScript } from '../';

export class MvrTab extends Component {
  state = { showAddressForm: true };

  toggleForm = () => {
    this.setState({ showAddressForm: !this.state.showAddressForm });
  };

  render() {
    const { props } = this;
    return (
      <Tab label="Run MVR" {...props}>
        <RequestMVRScript />
      </Tab>
    );
  }
}
