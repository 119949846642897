import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  SimpleForm,
  LongTextInput,
  SaveButton,
  Toolbar,
  GET_ONE,
  UPDATE,
  FormDataConsumer
} from 'react-admin';
import dataProvider from 'services/api';
import { Button } from '@material-ui/core';
import _ from 'lodash';

export const CandidateEditNoteToolbar = props => (
  <Toolbar>
    <SaveButton onClick={props.handleSave} disabled={props.disabled} />
    <Button style={{ color: '#f44336' }} onClick={props.handleClose}>
      Cancel
    </Button>
  </Toolbar>
);

export class CandidateEditNote extends Component {
  static propTypes = {
    setEditNote: PropTypes.func,
    setReady: PropTypes.func,
    id: PropTypes.string
  };
  static styles = {
    container: {
      background: 'white',
      boxShadow:
        '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)'
    }
  };
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      reasons: ''
    };
    this.dataProvider = dataProvider;
  }
  componentDidMount() {
    this.getReasons();
  }
  getReasons = async () => {
    this.props.setReady(false);
    const { id } = this.props;
    const resource = 'fcra_workers_adverse';
    const action = 'note';
    const response = await this.dataProvider(GET_ONE, resource, { id, action });
    this.handleResponse(response, 'getReasons');
  };
  setReasons = reasons => {
    if (!_.isString(reasons)) return this.state.reasons;
    this.setState({ reasons: reasons });
  };
  setForm = form => {
    if (form !== this.state.form) this.setState({ form });
  };
  handleResponse = (response, type) => {
    switch (type) {
      case 'getReasons':
        if (!_.isString(response.data['note'])) this.handleClose();
        this.setReasons(response.data['note']);
        this.props.setReady(true);
        break;
      case 'handleSubmit':
        if (!_.isObject(response.data)) this.handleClose();
        this.setReasons(response.data['note']);
        this.handleClose();
        window.location.reload();
        break;
      default:
        this.handleClose();
        break;
    }
  };
  handleClose = () => {
    this.setState({ formData: {}, reasons: '' });
    this.props.setReady(true);
    this.props.setEditNote();
  };
  handleSave = async () => {
    this.props.setReady(false);
    const { id } = this.props;
    const { form } = this.state;
    const resource = 'fcra_workers_adverse';
    const target = 'note';
    const reasons = form.reasons;
    const data = { reasons };
    const response = await this.dataProvider(UPDATE, resource, {
      id,
      target,
      data
    });
    this.handleResponse(response, 'handleSubmit');
  };
  getDisabled = () => _.isUndefined(this.state.form.reasons);
  render() {
    return (
      <div style={CandidateEditNote.styles.container}>
        <SimpleForm
          initialValues={this.defaultValues}
          toolbar={
            <CandidateEditNoteToolbar
              handleSave={this.handleSave}
              handleClose={this.handleClose}
              disabled={this.getDisabled()}
            />
          }
        >
          <LongTextInput
            source="reasons"
            defaultValue={this.state.reasons}
            label="Add a note"
          />
          <FormDataConsumer>
            {({ formData }) => {
              this.setForm(formData);
            }}
          </FormDataConsumer>
        </SimpleForm>
      </div>
    );
  }
}
