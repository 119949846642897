import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SaveButton } from 'react-admin';

import { addressAdd } from 'actions';

export const getAddressPayload = values => {
  const {
    address1,
    address2,
    city,
    zip,
    zip4,
    latitude,
    longitude,
    is_current_address,
    first_seen,
    last_seen,
    county
  } = values;
  const state = values.state.split('US-')[1] || values.state;
  const data = {
    address1,
    address2,
    city,
    state,
    zip,
    zip4,
    latitude,
    longitude,
    is_current_address,
    first_seen,
    last_seen,
    county
  };
  return data;
};

export class AddAddressButtonView extends Component {
  static propTypes = {
    addAddress: PropTypes.func.isRequired
  };
  static contextTypes = {
    router: PropTypes.object
  };
  handleClick = () => {
    const { invalid, handleSubmit, values, addressAdd } = this.props;
    const { id } = values;
    const data = getAddressPayload(values);
    const redirectTo = this.context.router.route.location.pathname;
    return handleSubmit(e => {
      if (invalid) return;
      addressAdd(id, data, redirectTo);
    });
  };
  render() {
    const { handleSubmitWithRedirect, ...props } = this.props;
    return (
      <SaveButton handleSubmitWithRedirect={this.handleClick} {...props} />
    );
  }
}

export const AddAddressButton = connect(
  null,
  { addressAdd }
)(AddAddressButtonView);
