import React, { useState } from 'react';
import { Show } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { ActionFactory } from 'components/actions';
import { SummaryTab } from '.';
import { styles } from './styles';
import DotsLoader from '../../../components/DotsLoader';
import { Dialog } from '../../../components';
import { CandidateEditNote } from '../editNote';
import { CandidateEditDispute } from '../editDispute';

const Actions = ActionFactory({
  editNote: true,
  editDispute: true,
  sendAdverse: true,
  sendPreAdverse: true,
  resumeChecks: false,
  edit: false,
  list: false,
  delete: false,
  refresh: false
});

export const AdverseView = props => {
  const [editNote, setEditNote] = useState(props.editNote === true);
  const [editDispute, setEditDispute] = useState(props.editDispute === true);
  const [ready, setReady] = useState(props.ready !== false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const toggleEditNote = () => setEditNote(!editNote);
  const toggleEditDispute = () => setEditDispute(!editDispute);
  return (
    <>
      <Dialog
        message={dialogMessage}
        title={dialogTitle}
        open={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
      {!ready && <DotsLoader />}
      {editNote && (
        <CandidateEditNote
          setReady={setReady}
          setEditNote={toggleEditNote}
          {...props}
        />
      )}
      {editDispute && (
        <CandidateEditDispute
          setReady={setReady}
          setEditDispute={toggleEditDispute}
          {...props}
        />
      )}
      <Show
        {...props}
        title="Adverse"
        actions={
          <Actions
            setReady={setReady}
            setEditNote={toggleEditNote}
            setEditDispute={toggleEditDispute}
            setDialogOpen={setDialogOpen}
            setDialogMessage={setDialogMessage}
            setDialogTitle={setDialogTitle}
          />
        }
        style={{ display: editNote ? 'none' : null }}
        style={{ display: editDispute ? 'none' : null }}
      >
        <SummaryTab {...props} />
      </Show>
    </>
  );
};

export default withStyles(styles)(AdverseView);
