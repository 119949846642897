import React from 'react';
import ReactDOM from 'react-dom';
import bugsnag from 'bugsnag-js';
import createPlugin from 'bugsnag-react';
import './index.css';
import App from './App';
import auth from './services/auth';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import { unregister } from './registerServiceWorker';

const bugsnagClient = bugsnag(runtimeEnv().REACT_APP_BUGSNAG_KEY);
const ErrorBoundary = bugsnagClient.use(createPlugin(React));

ReactDOM.render(
  <ErrorBoundary>
    <App auth={auth} />
  </ErrorBoundary>,
  document.getElementById('root')
);
unregister();
