export const oneOffChecksStyles = {
  button: {
    background: '#3f51b5',
    color: '#fff',
    cursor: 'pointer',
    disabled: {
      background: '#3f51b5',
      color: '#fff',
      cursor: 'pointer',
      background: 'rgb(0,0,0, .12)'
    }
  }
};
