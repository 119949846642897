import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SimpleForm, TextInput, Create, FormDataConsumer } from 'react-admin';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';

import { TurnDateInput } from 'components/inputs';
import { styles } from '../styles';
import { StateInput, CountyInput, ProviderInput } from '../';
import { required } from 'utils/validation';
import { RequestCountyToolbar } from './county';

const validate = required();
export class RequestCounty extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired
  };
  constructor(props) {
    super(props);
    this.state = { state: undefined, counties: [] };
  }
  setCurrentState(state) {
    if (state !== this.state.state) this.setState({ state });
  }
  setForm = form => {
    if (form !== this.state.form) this.setState({ form });
  };
  handleSubmit = save => save;
  render() {
    const { show, close } = this.props;
    return !show ? null : (
      <div>
        <Button
          mini
          variant="fab"
          color="secondary"
          aria-label="Add"
          onClick={close}
          style={styles.buttons}
        >
          {/* className={classes.button} */}
          <ClearIcon />
        </Button>
        <Create {...this.props} title="Request County Criminal">
          <SimpleForm
            style={styles.form}
            toolbar={
              <RequestCountyToolbar
                handleSubmit={this.handleSubmit}
                values={this.state.form}
              />
            }
            handleSubmit={this.handleSubmit}
            submitOnEnter={false}
          >
            <TextInput source="first_name" validate={validate} />
            <TextInput source="last_name" validate={validate} />
            <TurnDateInput
              source="date_of_birth"
              label="DOB"
              options={{ disableFuture: true }}
              validate={validate}
            />
            <StateInput />
            <CountyInput state={this.state.state} validate={validate} />
            <ProviderInput />
            <FormDataConsumer>
              {({ formData }) => {
                this.setCurrentState(formData.state);
                this.setForm(formData);
              }}
            </FormDataConsumer>
          </SimpleForm>
        </Create>
      </div>
    );
  }
}
