export const states = [
  { state: 'US-AL', name: 'Alabama' },
  { state: 'US-AK', name: 'Alaska' },
  { state: 'US-AZ', name: 'Arizona' },
  { state: 'US-AR', name: 'Arkansas' },
  { state: 'US-CA', name: 'California' },
  { state: 'US-CO', name: 'Colorado' },
  { state: 'US-CT', name: 'Connecticut' },
  { state: 'US-DE', name: 'Delaware' },
  { state: 'US-DC', name: 'District Of Columbia' },
  { state: 'US-FL', name: 'Florida' },
  { state: 'US-GA', name: 'Georgia' },
  { state: 'US-HI', name: 'Hawaii' },
  { state: 'US-ID', name: 'Idaho' },
  { state: 'US-IL', name: 'Illinois' },
  { state: 'US-IN', name: 'Indiana' },
  { state: 'US-IA', name: 'Iowa' },
  { state: 'US-KS', name: 'Kansas' },
  { state: 'US-KY', name: 'Kentucky' },
  { state: 'US-LA', name: 'Louisiana' },
  { state: 'US-ME', name: 'Maine' },
  { state: 'US-MD', name: 'Maryland' },
  { state: 'US-MA', name: 'Massachusetts' },
  { state: 'US-MI', name: 'Michigan' },
  { state: 'US-MN', name: 'Minnesota' },
  { state: 'US-MS', name: 'Mississippi' },
  { state: 'US-MO', name: 'Missouri' },
  { state: 'US-MT', name: 'Montana' },
  { state: 'US-NE', name: 'Nebraska' },
  { state: 'US-NV', name: 'Nevada' },
  { state: 'US-NH', name: 'New Hampshire' },
  { state: 'US-NJ', name: 'New Jersey' },
  { state: 'US-NM', name: 'New Mexico' },
  { state: 'US-NY', name: 'New York' },
  { state: 'US-NC', name: 'North Carolina' },
  { state: 'US-ND', name: 'North Dakota' },
  { state: 'US-OH', name: 'Ohio' },
  { state: 'US-OK', name: 'Oklahoma' },
  { state: 'US-OR', name: 'Oregon' },
  { state: 'US-PA', name: 'Pennsylvania' },
  { state: 'US-RI', name: 'Rhode Island' },
  { state: 'US-SC', name: 'South Carolina' },
  { state: 'US-SD', name: 'South Dakota' },
  { state: 'US-TN', name: 'Tennessee' },
  { state: 'US-TX', name: 'Texas' },
  { state: 'US-UT', name: 'Utah' },
  { state: 'US-VT', name: 'Vermont' },
  { state: 'US-VA', name: 'Virginia' },
  { state: 'US-WA', name: 'Washington' },
  { state: 'US-WV', name: 'West Virginia' },
  { state: 'US-WI', name: 'Wisconsin' },
  { state: 'US-WY', name: 'Wyoming' }
];
