import React from 'react';
import {
  Filter,
  // SelectInput,
  ReferenceInput,
  TextInput,
  AutocompleteInput
} from 'react-admin';

export const CandidateFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Partner"
      source="partner__id"
      reference="admin/partners"
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput
        optionText="name"
        optionValue="id"
        translateChoice={false}
      />
    </ReferenceInput>
    <TextInput source="q" label="Search" alwaysOn />
  </Filter>
);
