import React, { Component } from 'react';
import { Admin, Resource } from 'react-admin/lib';
import UserIcon from '@material-ui/icons/Group';
import DraftsIcon from '@material-ui/icons/Drafts';
import './pages/styles/app.css';
import { CandidateList, CandidateEdit, CandidateView } from './pages/candidate';
import { NationalRecordEdit } from './pages/national_record/edit';
import { AdverseList, AdverseView } from './pages/adverseaction';
import Callback from './pages/callback';
import Menu from './settings/menu';
import history from './settings/history';
import turnProvider from './services/api';
import Login from './pages/login';
import { preserveUrl } from './utils/preserveUrl';

class App extends Component {
  goTo(route) {
    history.replace(`/${route}`);
  }

  login() {
    this.props.auth.login();
    return null;
  }

  logout() {
    localStorage.setItem('logged_in', false);
    this.props.auth.logout();
  }
  shouldAuthenticate = () =>
    /access_token|id_token|error/.test(window.location.hash);
  showLoginPage = () => {
    const { isAuthenticated } = this.props.auth;
    if (this.shouldAuthenticate()) {
      return false;
    } else if (!isAuthenticated()) {
      return true;
    } else {
      return false;
    }
  };

  componentDidMount() {
    if (this.showLoginPage()) this.goTo('login');
    this.listener = history.listen(() => {
      const { pathname, search } = window.location;
      preserveUrl(pathname, search);
    });
  }
  componentWillUnmount() {
    this.listener = null;
  }

  render() {
    if (this.shouldAuthenticate()) {
      this.props.auth.handleAuthentication();
      return <Callback />;
    }
    return (
      <Admin
        title="Turn Admin"
        dataProvider={turnProvider}
        history={history}
        loginPage={Login}
        menu={Menu}
      >
        <Resource
          name="candidates"
          icon={UserIcon}
          list={CandidateList}
          edit={CandidateEdit}
          show={CandidateView}
        />
        <Resource
          name="adverse"
          icon={DraftsIcon}
          list={AdverseList}
          show={AdverseView}
        />
        <Resource name="cm_criminal" />
        <Resource
          name="national-record"
          icon={DraftsIcon}
          edit={NationalRecordEdit}
        />
        <Resource name="admin/partners" />
        <Resource name="turn_admin/states" />
        <Resource name="turn_admin/service_providers" />
      </Admin>
    );
  }
}

export default App;
