import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'react-admin';
import _ from 'lodash';

const FilteredName = ({ record: _record = {}, source = '', ...rest }) => {
  if (!_.isString(_record[source]))
    return <TextField source={source} record={_record} />;
  const value = _record[source].length < 200 ? _record[source] : 'pen-test';
  const record = { ..._record, [source]: value };

  return <TextField record={record} source={source} {...rest} />;
};

FilteredName.propTypes = {
  record: PropTypes.object
};
FilteredName.defaultProps = {
  record: {}
};

export default FilteredName;
