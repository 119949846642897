import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Labeled } from 'react-admin';

const LinkField = ({ record, source, label }) =>
  record && source && record[source] ? (
    <Labeled label={label || _.startCase(source)}>
      <a href={record[source]}>Download PA Release form</a>
    </Labeled>
  ) : null;

LinkField.propTypes = {
  record: PropTypes.object,
  label: PropTypes.string
};
LinkField.defaultProps = {
  record: {}
};

export default LinkField;
