import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Labeled } from 'react-admin';
import _ from 'lodash';

const CustomFields = props => {
  let customFields = [];
  if (props.record.one_off_checks !== undefined) {
    customFields = props.record.one_off_checks.custom_fields;
  }

  if (
    _.isEmpty(customFields) ||
    !_.isArray(customFields) ||
    _.isUndefined(customFields)
  )
    return null;
  return customFields.map((record, i) => (
    <div key={i}>
      <Labeled label={record.label || ''}>
        <TextField record={record} source={'value'} />
      </Labeled>
    </div>
  ));
};

CustomFields.propTypes = {
  record: PropTypes.object
};
CustomFields.defaultProps = {
  record: {}
};

export default CustomFields;
