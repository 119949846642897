export const STREET_NUMBER = 'street_number';
export const ROUTE = 'route';
export const NEIGHBORHOOD = 'neighborhood';
export const LOCALITY = 'locality';
export const ADMINISTRATIVE_AREA_LEVEL_2 = 'administrative_area_level_2';
export const ADMINISTRATIVE_AREA_LEVEL_1 = 'administrative_area_level_1';
// const COUNTY = "country"
export const POSTAL_CODE = 'postal_code';
export const POSTAL_CODE_SUFFIX = 'postal_code_suffix';

export const geosuggestToAddress = geosuggestPayload => {
  const { address_components: ac } = geosuggestPayload.gmaps;
  const { location: loc } = geosuggestPayload;
  const getComponentValue = (name, short = false) => {
    const component = ac.find(c => c.types.includes(name));
    return !component
      ? ''
      : !short
        ? component.long_name
        : component.short_name;
  };

  const number = getComponentValue(STREET_NUMBER);
  const street = getComponentValue(ROUTE);
  const address1 = `${number} ${street}`.trim();
  const address2 = getComponentValue(NEIGHBORHOOD);
  const city = getComponentValue(LOCALITY);
  const county = getComponentValue(ADMINISTRATIVE_AREA_LEVEL_2);
  let state = getComponentValue(ADMINISTRATIVE_AREA_LEVEL_1, true);
  state = state ? `US-${state}` : state;
  const zip = getComponentValue(POSTAL_CODE);
  const zip4 = getComponentValue(POSTAL_CODE_SUFFIX);
  const latitude = loc.lat;
  const longitude = loc.lng;

  return {
    address1,
    address2,
    city,
    county,
    state,
    zip,
    zip4,
    latitude,
    longitude
  };
};
