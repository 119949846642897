const stateList = [
  { id: 'AK', state: 'Alaska' },
  { id: 'AL', state: 'Alabama' },
  { id: 'AR', state: 'Arkansas' },
  { id: 'AS', state: 'American Samoa' },
  { id: 'AR', state: 'Arizona' },
  { id: 'CA', state: 'California' },
  { id: 'CO', state: 'Colorado' },
  { id: 'CT', state: 'Connecticut' }
  // { id: "", state: "District of Columbia",}
  // { id: "", state: "Delaware",}
  // { id: "", state: "Florida",}
  // { id: "", state: "Georgia",}
  // { id: "", state: "Guam",}
  // { id: "", state: "Hawaii",}
  // { id: "", state: "Iowa",}
  // { id: "", state: "Idaho",}
  // { id: "", state: "Illinois",}
  // { id: "", state: "Indiana",}
  // { id: "", state: "Kansas",}
  // { id: "", state: "Kentucky",}
  // { id: "", state: "Louisiana",}
  // { id: "", state: "Massachusetts",}
  // { id: "", state: "Maryland",}
  // { id: "", state: "Maine",}
  // { id: "", state: "Michigan",}
  // { id: "", state: "Minnesota",}
  // { id: "", state: "Missouri",}
  // { id: "", state: "Mississippi",}
  // { id: "", state: "Montana",}
  // { id: "", state: "North Carolina",}
  // { id: "", state: " North Dakota",}
  // { id: "", state: "Nebraska",}
  // { id: "", state: "New Hampshire",}
  // { id: "", state: "New Jersey",}
  // { id: "", state: "New Mexico",}
  // { id: "", state: "Nevada",}
  // { id: "", state: "New York",}
  // { id: "", state: "Ohio",}
  // { id: "", state: "Oklahoma",}
  // { id: "", state: "Oregon",}
  // { id: "", state: "Pennsylvania",}
  // { id: "", state: "Puerto Rico",}
  // { id: "", state: "Rhode Island",}
  // { id: "", state: "South Carolina",}
  // { id: "", state: "South Dakota",}
  // { id: "", state: "Tennessee",}
  // { id: "", state: "Texas",}
  // { id: "", state: "Utah",}
  // { id: "", state: "Virginia",}
  // { id: "", state: "Virgin Islands",}
  // { id: "", state: "Vermont",}
  // { id: "", state: "Washington",}
  // { id: "", state: "Wisconsin",}
  // { id: "", state: "West Virginia",}
  // { id: "", state: "Wyoming"}
];

const exampleCounties = [
  'Alameda',
  'Alpine',
  'Amador',
  'Butte',
  'Calaveras',
  'Colusa',
  'Contra Costa',
  'Del Norte',
  'El Dorado',
  'Fresno',
  'Glenn',
  'Humboldt',
  'Imperial',
  'Inyo',
  'Kern',
  'Kings',
  'Lake',
  'Lassen',
  'Los Angeles',
  'Madera',
  'Marin',
  'Mariposa',
  'Mendocino',
  'Merced',
  'Modoc',
  'Mono',
  'Monterey',
  'Napa',
  'Nevada',
  'Orange',
  'Placer',
  'Plumas',
  'Riverside',
  'Sacramento',
  'San Benito',
  'San Bernardino',
  'San Diego',
  'San Francisco',
  'San Joaquin',
  'San Luis Obispo',
  'San Mateo',
  'Santa Barbara',
  'Santa Clara',
  'Santa Cruz',
  'Shasta',
  'Sierra',
  'Siskiyou',
  'Solano',
  'Sonoma',
  'Stanislaus',
  'Sutter',
  'Tehama',
  'Trinity',
  'Tulare',
  'Tuolumne',
  'Ventura',
  'Yolo',
  'Yuba'
];

// const states = stateList.map((state, i) => { return { state, id: i + 1 }})
const counties = exampleCounties.map((county, i) => {
  return { county, id: i + 1 };
});
const getState = id => {
  const state = stateList.find(state => state.id === id);
  state.counties = counties;
  return state;
};
const countyListPayload = {
  data: counties,
  total: counties.length
};

export default {
  data: stateList,
  total: stateList.length
};
export { getState, countyListPayload };
