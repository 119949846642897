import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SaveButton } from 'react-admin';

import { requestMVRScriptAction } from '../../actions';

export class SubmitMVRScriptButtonView extends Component {
  static propTypes = {
    requestMVRScriptAction: PropTypes.func.isRequired
  };
  static contextTypes = {
    router: PropTypes.object
  };
  handleClick = () => {
    const {
      invalid,
      handleSubmit,
      values,
      requestMVRScriptAction
    } = this.props;
    const target = 'control_numbers';

    const { compass_order_report_id, control_number } = values;
    const data = {
      compass_order_report_id,
      control_number
    };
    const redirectTo = this.context.router.route.location.pathname;
    return handleSubmit(e => {
      if (invalid) return;
      requestMVRScriptAction(
        target,
        compass_order_report_id,
        control_number,
        redirectTo
      );
    });
  };
  render() {
    const { handleSubmitWithRedirect, saveWithNote, ...props } = this.props;
    return (
      <SaveButton handleSubmitWithRedirect={this.handleClick} {...props} />
    );
  }
}

export const SubmitMVRScriptButton = connect(
  undefined,
  { requestMVRScriptAction }
)(SubmitMVRScriptButtonView);
