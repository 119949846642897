import React, { useState } from 'react';
import { Show, TabbedShowLayout } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { ActionFactory } from 'components/actions';
import {
  SummaryTab,
  AddressesTab,
  ChecksTab,
  OneOffChecksTab,
  CriminalRecordsTab,
  CmActionsTab,
  SexRecordsTab,
  NationalRecordsTab,
  FederalRecordsTab,
  WatchlistRecordsTab,
  MvrAccidentTab,
  MvrActionTab,
  MvrViolationTab,
  MvrTab,
  MvrLicenseTab
} from '.';
import { styles } from './styles';
import DotsLoader from '../../../components/DotsLoader';
import { CandidateEditStatus } from '../editStatus';
import { Dialog } from '../../../components';

const Actions = ActionFactory({
  delete: false,
  setPending: true,
  editStatus: true,
  resumeChecks: true
});

export const CandidateView = props => {
  const [ready, setReady] = useState(props.ready !== false);
  const [editStatus, setEditStatus] = useState(props.editStatus === true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const toggleEditStatus = () => setEditStatus(!editStatus);

  return (
    <>
      <Dialog
        message={dialogMessage}
        title={dialogTitle}
        open={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
      {!ready && <DotsLoader />}
      {editStatus && (
        <CandidateEditStatus
          setReady={setReady}
          setEditStatus={toggleEditStatus}
          {...props}
        />
      )}
      <Show
        {...props}
        actions={
          <Actions
            setReady={setReady}
            setEditStatus={toggleEditStatus}
            setDialogOpen={setDialogOpen}
            setDialogMessage={setDialogMessage}
            setDialogTitle={setDialogTitle}
          />
        }
        style={{ display: editStatus ? 'none' : null }}
      >
        <TabbedShowLayout>
          <SummaryTab />
          <AddressesTab path="addresses" {...props} />
          <ChecksTab path="checks" {...props} />
          <OneOffChecksTab
            path="one_off_checks"
            setReady={setReady}
            {...props}
          />
          <CmActionsTab path="cm_actions" {...props} />
          <MvrTab path="mvr" {...props} />

          <CriminalRecordsTab path="criminal_records" {...props} />
          <SexRecordsTab path="sexoff_records" {...props} />
          <NationalRecordsTab path="national_records" {...props} />
          <WatchlistRecordsTab path="watchlist_records" {...props} />
          <FederalRecordsTab path="federal_records" {...props} />
          <MvrActionTab path="mvr_actions" {...props} />
          <MvrAccidentTab path="mvr_accidents" {...props} />
          <MvrViolationTab path="mvr_violations" {...props} />
          <MvrLicenseTab path="mvr_license" {...props} />
        </TabbedShowLayout>
      </Show>
    </>
  );
};

export default withStyles(styles)(CandidateView);
