import React from 'react';
import Dot from './Dot';

const styles = {
  container: {
    position: 'fixed',
    left: '0',
    top: '0',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#ffffff8a',
    zIndex: 99999
  },
  content: {
    display: 'inline-block',
    position: 'relative',
    width: '64px',
    height: '64px'
  }
};

const DotsLoader = () => (
  <div style={styles.container}>
    <div style={styles.content}>
      <Dot />
      <Dot />
      <Dot />
    </div>
  </div>
);

export default DotsLoader;
