import { BG_COLOR_KEY_MAPPING } from '../constants';

const { dark_amber, dark_green, dark_grey, red } = BG_COLOR_KEY_MAPPING;

export const COLOR_KEY_DATA = [
  {
    label: 'Exact Match',
    styles: dark_green.styles
  },
  {
    label: 'Partial Match',
    styles: dark_amber.styles
  },
  {
    label: 'No Match',
    styles: red.styles
  },
  {
    label: 'NA',
    styles: dark_grey.styles
  }
];
