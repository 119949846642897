import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AddressSetCurrentButton } from '../buttons';

const buttonStyle = { textTransform: 'none' };

class AddressActionField extends Component {
  render() {
    const { record } = this.props;
    return <AddressSetCurrentButton record={record} style={buttonStyle} />;
  }
}

AddressActionField.propTypes = {
  record: PropTypes.object
};
AddressActionField.defaultProps = {
  record: {}
};
AddressActionField.contextTypes = {
  hover: PropTypes.bool
};

export default AddressActionField;
