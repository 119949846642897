import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  SimpleForm,
  SelectInput,
  FormDataConsumer,
  Toolbar,
  SaveButton
} from 'react-admin';
import BackButton from './BackButton';
import { CountyInput } from '../candidate/view/forms';
import { states } from './states';

const UserEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export class NationalRecordEdit extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      state: undefined,
      counties: [],
      first_field: false,
      second_field: false
    };
  }

  setCurrentState(state) {
    if (state !== this.state.state) this.setState({ state });
  }
  setCurrentPW(pw) {
    if (pw !== this.state.pw) this.setState({ pw });
  }
  setForm = form => {
    if (form !== this.state.form) this.setState({ form });
  };
  handleSubmit = save => save;

  onChangeStateHandler = params => (this.state.first_field = true);
  onChangeCountyHandler = params => (this.state.second_field = true);

  render() {
    const redirect = (basePath, id, data) =>
      `/candidates/${this.state.pw}/show/national_records`;
    let isShown =
      this.state.first_field == false && this.state.second_field == false;
    return (
      <div>
        <BackButton />
        <Edit title={'Edit National Record Entry'} {...this.props}>
          <SimpleForm
            submitOnEnter={false}
            redirect={redirect}
            toolbar={<UserEditToolbar />}
          >
            <SelectInput
              optionValue="state"
              source="state"
              choices={states}
              label="State"
              optionText="name"
              disabled={true}
              onChange={this.onChangeStateHandler}
            />
            <CountyInput
              state={this.state.state}
              onChange={this.onChangeCountyHandler}
            />
            {isShown && (
              <span style={{ color: 'red' }}>All fields must be selected</span>
            )}

            <FormDataConsumer>
              {({ formData }) => {
                this.setCurrentState(formData.state);
                this.setCurrentPW(formData.pw);
                this.setForm(formData);
              }}
            </FormDataConsumer>
          </SimpleForm>
        </Edit>
      </div>
    );
  }
}
