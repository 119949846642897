import React from 'react';
import { Tab, DateField, EmailField, FunctionField } from 'react-admin';
import {
  ConditionalTextField as TextField,
  PendingChecksField,
  StatusHistoryTable,
  CustomFields,
  LinkField,
  DocumentTable
} from 'components/fields';

export const SummaryTab = props => {
  return (
    <Tab label="info" {...props}>
      <TextField source="turn_id" label="Turn Id" />
      <TextField source="id" />
      <FunctionField
        label="DDPPAR"
        render={record => (
          <span>
            {record.is_ddppar == 'True'
              ? 'This applicant requested to have their Personal Identifiable Information deleted'
              : 'No'}
          </span>
        )}
      />
      <TextField source="reference_id" label="Reference Id" />
      <TextField source="last_four_ssn" label="SSN Last Four Digits" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="middle_name" />
      <TextField source="phone_number" label="Phone Number" />
      <TextField source="candidate_provided_name" label="Name Entered" />
      <TextField source="public_provided_name" label="Public Records Name" />
      <TextField
        source="candidate_provided_date_of_birth"
        label="DOB Entered"
      />
      <TextField
        source="public_provided_date_of_birth"
        label="Public Records DOB"
      />
      <TextField source="drivers_license_number" />
      <TextField source="drivers_license_state" />
      <TextField source="gender" />
      <TextField source="profile_status" />
      <TextField source="partner_name" label="Partner" />
      <TextField source="candidate_email" label="Email" />
      <TextField source="zipcode" label="Zip" />
      <TextField source="has_cm_recheck" label="CM Recheck" />
      <DateField source="date_initiated" label="Publication date" />
      <PendingChecksField />
      <CustomFields />
      <StatusHistoryTable source="status_history" />
      <DocumentTable source="documents" />
    </Tab>
  );
};
