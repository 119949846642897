/* 
 *  Component to bold the text if the address record is current
 *  Can be extended to allow for overwriting of the getStyles() 
 *  method to satisfy whatever conditions and style are required
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pure from 'recompose/pure';
import Typography from '@material-ui/core/Typography';

class AddressTextField extends Component {
  getStyles() {
    const { record, style } = this.props;
    const recordDependantStyle = get(record, 'is_current_address')
      ? { fontWeight: 700 }
      : {};
    return Object.assign(recordDependantStyle, style);
  }
  render() {
    const { className, source, record } = this.props;
    return (
      <Typography
        component="span"
        body1="body1"
        className={className}
        style={this.getStyles()}
      >
        {get(record, source)}
      </Typography>
    );
  }
}

AddressTextField.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired
};

const PureAddressTextField = pure(AddressTextField);

PureAddressTextField.defaultProps = {
  addLabel: true,
  record: {}
};

export default PureAddressTextField;
