import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import {
  SET_PENDING,
  EDIT_STATUS,
  RESUME_CHECKS,
  SEND_ADVERSE,
  SEND_PREADVERSE,
  EDIT_NOTE,
  EDIT_DISPUTE,
  DISABLE_CONSENT_LINK,
  MOVE_WORKER_FORWARD,
  RERUN_ADDRESSES
} from '../../providers/turn/const';
import { UPDATE, CREATE } from 'react-admin';
import {
  Edit,
  MoreHoriz,
  PlayArrow,
  LinkOff,
  ArrowForward,
  ImportContacts
} from '@material-ui/icons';
import _ from 'lodash';
import dataProvider from 'services/api';

class ActionButton extends Component {
  static contextTypes = {
    title: PropTypes.string,
    type: PropTypes.string
  };
  static propTypes = {
    setReady: PropTypes.func.isRequired,
    setEditStatus: PropTypes.func.isRequired,
    setEditNote: PropTypes.func.isRequired,
    setEditDispute: PropTypes.func.isRequired,
    setDialogOpen: PropTypes.func.isRequired,
    setDialogMessage: PropTypes.func.isRequired,
    setDialogTitle: PropTypes.func.isRequired
  };
  static styles = {
    container: {
      position: 'relative',
      paddingLeft: '16px'
    },
    button: {
      color: '#3f51b5',
      fontSize: '0.8125rem',
      paddingLeft: '32px'
    },
    icon: {
      color: '#3f51b5',
      border: '1px solid',
      borderRadius: '50%',
      position: 'absolute',
      top: '8px',
      height: '0.75em',
      width: '0.75em'
    },
    icon_no_border: {
      position: 'absolute',
      color: '#3f51b5',
      top: '1vh',
      fontSize: '20px'
    }
  };
  getTitle = title => (_.isString(title) ? title.toUpperCase() : null);
  getIcon = (type, style) => {
    const { styles } = ActionButton;
    switch (type) {
      case SET_PENDING:
        return <MoreHoriz style={style} />;
      case EDIT_STATUS:
        return <Edit style={styles.icon_no_border} />;
      case EDIT_NOTE:
        return <Edit style={styles.icon_no_border} />;
      case EDIT_DISPUTE:
        return <Edit style={styles.icon_no_border} />;
      case RESUME_CHECKS:
        return <PlayArrow style={style} />;
      case SEND_ADVERSE:
        return <PlayArrow style={style} />;
      case SEND_PREADVERSE:
        return <PlayArrow style={style} />;
      case DISABLE_CONSENT_LINK:
        return <LinkOff style={style} />;
      case MOVE_WORKER_FORWARD:
        return <ArrowForward style={style} />;
      case RERUN_ADDRESSES:
        return <ImportContacts style={style} />;

      default:
        return null;
    }
  };
  handleResponse = (response, title, message) => {
    if (response && response.data && response.data.state) {
      window.location.reload();
    } else if (response && response.data) {
      if (title && message) {
        this.props.setDialogOpen(true);
        this.props.setDialogTitle(title);
        this.props.setDialogMessage(message);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        window.location.reload();
      }
    } else {
      console.error(response);
    }
    this.props.setReady(true);
  };
  handleError = error => {
    this.props.setReady(true);
    this.props.setDialogOpen(true);
    if (error.body) {
      this.props.setDialogMessage(
        <span>
          {error.body.message && <p>{`Message: ${error.body.message}`}</p>}
          {error.body.request_id && (
            <p>{`Request id: ${error.body.request_id}`}</p>
          )}
        </span>
      );
    } else {
      this.props.setDialogMessage('If this persist please contact support');
    }
  };
  setRecordPending = async id => {
    this.props.setReady(false);
    const resource = 'fcra_workers';
    const target = 'actions';
    const action = 'reviewed';
    const response = await dataProvider(UPDATE, resource, {
      id,
      target,
      action
    });
    this.handleResponse(response);
  };
  resumeChecks = id => {
    this.props.setReady(false);
    const resource = 'fcra_workers';
    const target = 'resume_checks';
    dataProvider(UPDATE, resource, {
      id,
      target
    })
      .then(response =>
        this.handleResponse(response, 'Success!', 'Checks were resumed.')
      )
      .catch(error => this.handleError(error));
  };
  setPreadverseNotice = async id => {
    this.props.setReady(false);
    const resource = 'fcra_workers_adverse';
    const target = 'send_preadverse';
    const response = await dataProvider(UPDATE, resource, {
      id,
      target
    });
    this.handleResponse(response);
  };
  setAdverseNotice = async id => {
    this.props.setReady(false);
    const resource = 'fcra_workers_adverse';
    const target = 'send_adverse';
    const response = await dataProvider(UPDATE, resource, {
      id,
      target
    });
    this.handleResponse(response);
  };
  disableConsentLink = async id => {
    this.props.setReady(false);
    const resource = 'candidate_consent_email';
    const target = 'disable_consent_link';
    const response = await dataProvider(UPDATE, resource, {
      id,
      target
    })
      .then(response =>
        this.handleResponse(
          response,
          response.data.title,
          response.data.message
        )
      )
      .catch(error => this.handleError(error));
  };
  moveWorkerForward = async id => {
    this.props.setReady(false);
    const resource = 'fcra_workers';
    const target = 'move_worker_forward';
    const response = await dataProvider(UPDATE, resource, {
      id,
      target
    })
      .then(response =>
        this.handleResponse(
          response,
          response.data.title,
          response.data.message
        )
      )
      .catch(error => this.handleError(error));
  };

  rerunAddresses = async id => {
    this.props.setReady(false);
    const resource = 'fcra_workers';
    const target = 'rerun_addresses';
    const response = await dataProvider(CREATE, resource, {
      id,
      target
    })
      .then(response =>
        this.handleResponse(
          response,
          response.data.title,
          response.data.message
        )
      )
      .catch(error => this.handleError(error));
  };
  doAction = async type => {
    const { id } = this.props;
    switch (type) {
      case SET_PENDING:
        this.setRecordPending(id);
        break;
      case EDIT_STATUS:
      case EDIT_NOTE:
      case EDIT_DISPUTE:
        this.props.show();
        break;
      case RESUME_CHECKS:
        this.resumeChecks(id);
        break;
      case SEND_PREADVERSE:
        this.setPreadverseNotice(id);
        break;
      case SEND_ADVERSE:
        this.setAdverseNotice(id);
        break;
      case DISABLE_CONSENT_LINK:
        this.disableConsentLink(id);
        break;
      case MOVE_WORKER_FORWARD:
        this.moveWorkerForward(id);
        break;
      case RERUN_ADDRESSES:
        this.rerunAddresses(id);
        break;
      default:
        console.error(`Type not recognized ${type}`);
        break;
    }
  };
  render() {
    const { type, title } = this.props;
    const { container, button, icon } = ActionButton.styles;

    return (
      <div style={container} onClick={() => this.doAction(type)}>
        {this.getIcon(type, icon)}
        <Button style={button} color="primary">
          {this.getTitle(title)}
        </Button>
      </div>
    );
  }
}

export default ActionButton;
