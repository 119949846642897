import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  Tab,
  Datagrid,
  TextField,
  DateField,
  ReferenceManyField,
  FunctionField
} from 'react-admin';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from '@material-ui/core/Button';
import { CANDIDATE_RESOURCE } from 'providers/turn';
import { styles } from '../styles';
import { RequestCounty, RequestMVR } from '../';

const BLACKLIST = ['approved', 'rejected'];

export class ChecksTab extends Component {
  constructor(props) {
    super(props);
    this.state = { showRequestCounty: false, showRequestMVR: false };
  }
  toggleRequestCounty = () => {
    this.setState({
      showRequestCounty: !this.state.showRequestCounty
    });
  };
  closeForm = () => {
    if (this.state.showRequestCounty)
      this.setState({ showRequestCounty: false });
    if (this.state.showRequestMVR) this.setState({ showRequestMVR: false });
  };
  toggleRequestMVR = () => {
    this.setState({ showRequestMVR: !this.state.showRequestMVR });
  };
  formIsOpen = () => {
    const { showRequestCounty, showRequestMVR } = this.state;
    return showRequestCounty || showRequestMVR;
  };
  setCandidateStatus = status => {
    if (!this.state.status) this.setState({ status });
  };
  cannotRequestMVR = () => {
    return BLACKLIST.includes(this.state.status);
  };
  renderRequestButtons = () => {
    const {
      formIsOpen,
      toggleRequestMVR,
      toggleRequestCounty,
      cannotRequestMVR
    } = this;
    return formIsOpen() ? null : (
      <div>
        <Button
          onClick={toggleRequestMVR}
          style={styles.buttons}
          disabled={cannotRequestMVR()}
        >
          <AddCircleOutlineIcon /> Request MVR
        </Button>
        <Button onClick={toggleRequestCounty} style={styles.buttons}>
          <AddCircleOutlineIcon /> Request County
        </Button>
      </div>
    );
  };
  render() {
    const {
      formIsOpen,
      closeForm,
      setCandidateStatus,
      renderRequestButtons
    } = this;
    const { classes } = this.props;
    const { showRequestCounty, showRequestMVR } = this.state;

    return (
      <Tab label="requested checks" {...this.props}>
        <FunctionField
          render={record => setCandidateStatus(record.profile_status)}
          addLabel={false}
        />
        {renderRequestButtons()}
        {formIsOpen() ? null : (
          <ReferenceManyField
            reference={CANDIDATE_RESOURCE}
            target="background_checks"
            label=""
            sort={{ field: '_doc', order: 'DESC' }}
          >
            <Datagrid classes={classes ? { row: classes.row } : null}>
              <FunctionField
                render={record => _.startCase(record.kind)}
                label="Check"
              />
              <TextField source="status" />
              <TextField source="locality" />
              <TextField source="service" />
              <FunctionField
                render={record => {
                  if (!record.created_at) return 'N/A';
                  const end_date = moment(record.completed_at || undefined);
                  const start_date = moment(record.created_at);
                  return end_date.from(start_date, true);
                }}
                label="Processing Time"
              />
              <DateField label="Check Date" source="created_at" showTime />
            </Datagrid>
          </ReferenceManyField>
        )}
        <RequestCounty
          show={showRequestCounty}
          close={closeForm}
          {...this.props}
        />
        <RequestMVR show={showRequestMVR} close={closeForm} />
      </Tab>
    );
  }
}
