import React from 'react';
import _ from 'lodash';
import { TextField, Labeled } from 'react-admin';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const ConditionalTextField = ({ record, source, label, ...rest }) =>
  record && source && record[source] ? (
    <Labeled label={label || _.startCase(source)}>
      <CopyToClipboard text={record[source]}>
        <TextField
          source={source}
          record={record}
          {...rest}
          style={{ cursor: 'pointer' }}
          className="text-field"
        />
      </CopyToClipboard>
    </Labeled>
  ) : null;
