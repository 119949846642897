import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SimpleForm, TextInput, Create, FormDataConsumer } from 'react-admin';

import { styles } from '../styles';
import { MVRScriptToolbar } from './mvr_script';
import { required } from 'utils/validation';

const validate = required();

export class RequestMVRScript extends Component {
  static propTypes = {
    loader: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired
  };
  constructor(props) {
    super(props);
    this.state = { state: undefined, counties: [] };
  }
  setCurrentState(state) {
    if (state !== this.state.state) this.setState({ state });
  }
  setForm = form => {
    if (form !== this.state.form) this.setState({ form });
  };
  handleSubmit = save => {
    return save;
  };
  render() {
    return (
      <Create location={{}} {...this.props} title=" Request MVR Script">
        <SimpleForm
          style={styles.form}
          toolbar={
            <MVRScriptToolbar
              handleSubmit={this.handleSubmit}
              values={this.state.form}
            />
          }
          handleSubmit={this.handleSubmit}
          submitOnEnter={false}
        >
          <TextInput
            source="compass_order_report_id"
            label="Compass order report"
            placeholder="(5 digits)"
          />
          <TextInput
            source="control_number"
            label="Control number"
            placeholder="(8 digits)"
          />
          <FormDataConsumer>
            {({ formData }) => {
              this.setCurrentState(formData.state);
              this.setForm(formData);
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    );
  }
}
