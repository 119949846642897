export default {
  id: 1,
  candidate_email: 'mock@email.net',
  date_initiated: '2018-01-26T14:13:57.787398+00:00',
  display_name: 'John Smith',
  partner_name: 'MVR',
  uuid: 'b0ba7fc6-e101-4ef1-8ac1-86344d3352ce',
  first_name: 'John',
  last_name: 'Smith',
  middle_name: 'T',
  candidate_provided_name: 'John Smith',
  public_provided_name: 'John T Smith',
  candidate_provided_date_of_birth: '2/8/2019',
  public_provided_date_of_birth: '2/8/2019',
  drivers_license_state: 'IL',
  drivers_license_number: '0123456789',
  gender: 'M',
  zipcode: '97133',
  reference_id: 'Some Id'
};

export const addresses = [
  {
    id: 1,
    address1: '1042 BRADSHAW ESTATES DR',
    city: 'CANTON',
    first_seen: '2002-07-01',
    last_seen: '2017-08-30',
    state: 'GA',
    zip: '30115'
  },
  {
    id: 2,
    address1: '2042 BRADSHAW ESTATES DR',
    city: 'CANTON',
    first_seen: '2002-07-01',
    last_seen: '2017-08-30',
    state: 'GA',
    zip: '30115'
  },
  {
    id: 3,
    address1: '3042 BRADSHAW ESTATES DR',
    city: 'CANTON',
    first_seen: '2002-07-01',
    last_seen: '2017-08-30',
    state: 'GA',
    zip: '30115'
  },
  {
    id: 4,
    address1: '4042 BRADSHAW ESTATES DR',
    city: 'CANTON',
    first_seen: '2002-07-01',
    last_seen: '2017-08-30',
    state: 'GA',
    zip: '30115'
  }
];
