import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SaveButton } from 'react-admin';
import { requestCountyAction } from '../../actions';

const SEARCH_COUNTY = 'county_check';
export class SubmitCountySearchButtonView extends Component {
  static propTypes = {
    requestCountyAction: PropTypes.func.isRequired
  };
  static contextTypes = {
    router: PropTypes.object
  };
  handleClick = () => {
    const { invalid, handleSubmit, values, requestCountyAction } = this.props;
    const target = SEARCH_COUNTY;
    const {
      id,
      first_name,
      last_name,
      date_of_birth,
      state,
      county,
      provider
    } = values;
    var data = {
      first_name,
      last_name,
      date_of_birth,
      state,
      county
    };
    data = provider ? { ...data, provider: `${provider}` } : data;
    const redirectTo = this.context.router.route.location.pathname;
    return handleSubmit(e => {
      if (invalid) return;
      requestCountyAction(target, id, data, redirectTo);
    });
  };
  render() {
    const { handleSubmitWithRedirect, ...props } = this.props;
    return (
      <SaveButton handleSubmitWithRedirect={this.handleClick} {...props} />
    );
  }
}

export const SubmitCountySearchButton = connect(
  null,
  { requestCountyAction }
)(SubmitCountySearchButtonView);
