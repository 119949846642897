import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-admin';
import Save from '@material-ui/icons/Save';
import { oneOffChecksStyles as styles } from './styles';

export class SubmitOneOffCheckButton extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    model: PropTypes.object
  };
  static contextType = {
    router: PropTypes.object
  };

  render() {
    return (
      <Button
        style={
          this.props.model.disableSubmit
            ? styles.button.disabled
            : styles.button
        }
      >
        <Save color="#fff" />
      </Button>
    );
  }
}
